import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UiModule } from '@ov-suite/ui';
import { ChartsModule } from 'ng2-charts';
import { FormsModule } from '@angular/forms';
import { StatisticsRoutes } from './statistics-dashboard.routing';
import { StatisticsDashboardComponent } from './dashboard/statistics-dashboard.component';
import { ReportsServiceExitEntry, ReportsServiceVehicleTrack } from '../../services/reports/reports.service';

@NgModule({
  declarations: [StatisticsDashboardComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(StatisticsRoutes),
    UiModule,
    ChartsModule,
    FormsModule,
  ],
  providers: [
    ReportsServiceExitEntry,
    ReportsServiceVehicleTrack,
  ],
  exports: [StatisticsDashboardComponent],
})
export class StatisticsDashboardModule { }
