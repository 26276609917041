import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AutoService, OvAutoService } from '@ov-suite/services';
import {
  Constructor,
  FieldMetadata,
  GenericHierarchy,
  getFieldMetadata,
  getSearchableMetadata,
  PageReturn,
  SearchableMetadata,
} from '@ov-suite/ov-metadata';
import { ColumnData, getUrlQueryMap, queryParams, queryPlaceHolder } from '@ov-suite/helpers-shared';
import { hasPermissionByFeature, PermissionAction, verifyPermission } from '@ov-suite/authguard-angular';
import { cloneDeep } from 'lodash';
import { FilterChange } from '../table/table.component';
import { DateRange } from '../date-time/date-range.component';

type GenericHierarchyType = GenericHierarchy;

@Component({
  selector: 'ov-suite-hierarchy-table',
  templateUrl: './hierarchy-list.component.html',
  styleUrls: ['./hierarchy-list.component.scss'],
})
export class HierarchyListComponent<T extends GenericHierarchyType> implements OnInit {
  // To be Deprecated
  @Input() service: AutoService<T>;

  // To Be deprecated
  @Input() ovAutoService: OvAutoService;

  // To be Deprecated in favour of Fetching from Metadata
  @Input() api: string;

  @Input() title: string;

  @Input() formClass: Constructor<T>;

  @Input() hasBulkUpload = true;

  @Input() hasBulkExport = true;

  @Input() excludeColumns = 1;

  @Input() showTopBar = false;

  @Input() filterEnabled = true;

  @Input() showPageSelect = true;

  @Input() pageChangeEnabled = true;

  @Input() selectableRows = false;

  @Input() hideAddButton = false;

  @Input() showFiller = true;

  @Input() showScroll = false;

  @Input() editableRows = false;

  @Input() dropdownData = {};

  @Input() overrideServiceMethod = null;

  @Input() dataOverride: T[];

  @Input() emptyComponent;

  @Input() bulkActionComponent;

  @Input() searchDropdownPosition?: string;

  @Input()
  set reFetch(reFetch: number) {
    if (reFetch > 0) {
      this.getData();
    }
  }

  @Output() itemSelect = new EventEmitter<T[]>();

  @Output() editedItems = new EventEmitter<T[]>();

  @Output() executedBulkUpload = new EventEmitter();

  @Output() originalData = new EventEmitter<T[]>();

  @Input() overrideAddButtonClick = false;

  @Output() addButtonClick = new EventEmitter<T>();

  loading = false;

  customizing = false;

  selected: T[];

  _idFilter: Record<string, (string | number)[]>;

  _hideColumnKeys: string[] = [];

  updatedItems: Record<number, { newItem: T; oldItem: T }>;

  @Input() hasDeletePermission = true;

  @Input() hasEditPermission = true;

  @Input() hasCreatePermission = true;

  @Input() defaultOrderDirection: 'ASC' | 'DESC' = 'ASC';

  @ViewChild('uploadComponent') uploadComponent: ElementRef;

  @Input()
  set filter(event) {
    this._idFilter = event;
    this.fetchDataByUrl({});
  }

  get filter() {
    return this._idFilter;
  }

  @Input()
  set hideColumnKeys(event: string[]) {
    this._hideColumnKeys = event;
  }

  get hideColumnKeys() {
    return this._hideColumnKeys;
  }

  data: T[] = [];

  originalDataCopy: T[] = [];

  // Paging Variables
  totalCount = 0;

  _page = 0;

  set page(page: number) {
    this._page = page;
    this.fetchDataByUrl({ [queryParams.PAGE]: page });
  }

  get page() {
    return this._page;
  }

  pageSize = 10;

  searchableMetadata: SearchableMetadata;

  // End Paging Variables
  _parentId: number | null = null;

  set parentId(num: number) {
    this._parentId = num ?? null;
    this.fetchDataByUrl({ [queryParams.PARENT_ID]: this._parentId });
  }

  get parentId() {
    return this._parentId;
  }

  metadata: FieldMetadata<T>;

  searchFilter = '';

  searchMap: Record<string, string[]> = {};

  filterMap: Record<string, string[]> = {};

  currentFilterChange: FilterChange;

  // Checks what is not being filtered anymore and sets url params to null or removes them
  filterHistory: Record<string, string[]> = {};

  orderColumn = 'id';

  orderDirection: 'ASC' | 'DESC' = this.defaultOrderDirection;

  @Input() import: () => void = () => {};

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly location: Location,
    private readonly autoService: OvAutoService,
  ) {
    this.route.data.subscribe(perm => {
      if (perm?.feature?.id) {
        hasPermissionByFeature(perm.feature.id).then(permission => {
          this.hasDeletePermission = verifyPermission(permission, PermissionAction.DELETE);
          this.hasEditPermission = verifyPermission(permission, PermissionAction.UPDATE);
          this.hasCreatePermission = verifyPermission(permission, PermissionAction.CREATE);
        });
      }
    });
  }

  fetchDataByUrl(param: Record<string, unknown>) {
    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: { ...param },
        skipLocationChange: this.router.isActive(this.router.url, true), // Floods url history - this fixes it
        queryParamsHandling: 'merge',
      } as NavigationExtras)
      .then(() => this.getData());
  }

  pageSizeInput(pageSize: number) {
    if (pageSize > 0) {
      this.pageSize = +pageSize;
      this.fetchDataByUrl({ [queryParams.PAGE_SIZE]: this.pageSize });
    }
  }

  changePage(page: number): void {
    this.page = page;
    this.fetchDataByUrl({ [queryParams.PAGE]: this.page });
  }

  getData(): void {
    if (this.dataOverride) {
      this.data = this.dataOverride;
      this.totalCount = this.dataOverride.length;
      this.loading = false;
      this.originalData.emit(this.data);

      return;
    }
    const metadata = getFieldMetadata<T>(this.formClass);
    if (metadata.fields.some(f => f.propertyKey === 'parent')) {
      this._idFilter = {
        ...this._idFilter,
        parentId: [this.parentId || null],
      };
    }

    const keys: string[] = ['id'];

    metadata.table.forEach(col => {
      switch (col.type) {
        case 'other':
        case 'pills':
        case 'buttons':
          keys.push(...col.keys);
          break;
        case 'column':
          keys.push(...col.keys);
          keys.push(col.key as string);
          break;
        default:
          keys.push(col.key as string);
      }
    });

    this.loading = true;

    const handlePromise = (promise: Promise<PageReturn<T>>) => {
      promise
        .then(result => {
          this.data = result.data;
          this.originalDataCopy = cloneDeep(result.data);
          this.totalCount = result.totalCount;
          this.loading = false;
          this.originalData.emit(this.data);
        })
        .catch(() => {
          this.loading = false;
        });
    };

    const baseParams = {
      search: this.searchMap,
      filter: this.filterMap,
      query: this._idFilter, // TODO rename idFilter
      specifiedApi: this.api,
      limit: this.pageSize,
      offset: this.page * this.pageSize,
      orderDirection: this.defaultOrderDirection,
      orderColumn: this.orderColumn,
      keys,
    };

    if (this.overrideServiceMethod) {
      handlePromise(this.service[this.overrideServiceMethod](baseParams));
    } else if (this.service) {
      handlePromise(this.service.list(baseParams));
    } else {
      handlePromise(this.autoService.list({ ...baseParams, entity: this.formClass }));
    }
  }

  loadFromRoute() {
    this.route.queryParamMap.subscribe(params => {
      const { filterKeys, queryKeys } = getUrlQueryMap(params);
      this.parentId = params.get(queryParams.PARENT_ID) ? Number(params.get(queryParams.PARENT_ID)) : null;
      this.pageSize = params.get(queryParams.PAGE_SIZE) ? Number(params.get(queryParams.PAGE_SIZE)) : 10;
      this.page = Number(params.get(queryParams.PAGE));
      this.orderColumn = params.get(queryParams.ORDER_COLUMN) ?? 'id';
      this.orderDirection = (params.get(queryParams.ORDER_DIRECTION) as 'ASC' | 'DESC') ?? 'ASC';
      this.filterMap = filterKeys;
      this._idFilter = { ...this._idFilter, ...queryKeys };
      this.filterHistory = { ...filterKeys, ...queryKeys };
      this.currentFilterChange = {
        filter: filterKeys,
        query: queryKeys,
        search: this.searchMap,
      };
    });
  }

  ngOnInit() {
    this.loadFromRoute();
    this.getMetadata();
    this.searchableMetadata = getSearchableMetadata(this.formClass);
  }

  getMetadata(): void {
    const metadata = getFieldMetadata<T>(this.formClass);
    const extraFields: ColumnData<T>[] = [];
    if (this.excludeColumns >= 1) {
      const buttons = [];
      const editButton = {
        tooltip: 'Edit',
        classes: 'btn-primary btn-sm fa pt-1 pb-1 fa-pencil',
        action: (item: T) => {
          this.edit(item);
        },
      };
      const deleteButton = {
        tooltip: 'Delete',
        classes: 'btn-primary ml-1 btn-sm fa fa-trash-o',
        action: async (item: T) => {
          await this.delete(item);
        },
      };

      if (this.excludeColumns === 1) {
        if (this.hasEditPermission) {
          buttons.push(editButton);
        }

        if (this.hasDeletePermission) {
          buttons.push(deleteButton);
        }
      }

      if (this.excludeColumns === 2) {
        if (this.hasDeletePermission) {
          buttons.push(deleteButton);
        }
      }

      if (this.excludeColumns === 3) {
        if (this.hasEditPermission) {
          buttons.push(editButton);
        }
      }

      if (this.excludeColumns < 4 && buttons.length) {
        extraFields.push({
          type: 'buttons',
          title: 'Fast Actions',
          buttons,
          keys: [],
          disableFiltering: true,
          disableSorting: true,
          id: 'fast_actions',
        });
      }
    }
    if (this.excludeColumns === 0) {
      extraFields.push({
        type: 'status',
        title: 'Status',
        key: 'status',
        id: 'status',
      });
    }

    this.metadata = { ...metadata, table: [...metadata.table, ...extraFields] };
    // this.hideColumnKeys = this.metadata.table.filter(item => !!item.hideColumnKey).map(item => item.hideColumnKey);
  }

  onSearchChange(event: Event): void {
    if ((event?.target as HTMLInputElement)?.value) {
      this.search((event?.target as HTMLInputElement)?.value);
    } else {
      this.search('');
      this.searchFilter = '';
      this.searchMap = {};
    }
  }

  onClearSearch(filter): void {
    this.search('');
    this.searchFilter = '';
    this.searchMap = {};
    this.filterMap = {};
    this._idFilter = {};
    this.filterChange(filter);
  }

  search(text: string): void {
    let filter = '';
    const { name } = this.metadata;
    this.searchableMetadata.fields.forEach(field => {
      this.searchMap[field.propertyKey] = [text];

      if (filter) {
        filter += ` OR "${name}"."${field.propertyKey}" ILIKE '%${text}%'`;
      } else {
        filter = `"${name}"."${field.propertyKey}" ILIKE '%${text}%'`;
      }
    });

    this.searchFilter = `(${filter})`;
    this.page = 0;
    this.getData();
  }

  onAdvancedFilterChange(filter: FilterChange) {
    this.currentFilterChange = filter;
    this.filterChange(filter);
  }

  customize() {
    this.customizing = !this.customizing;
  }

  add(): void {
    const options: NavigationExtras = {};

    if (this.parentId) {
      options.queryParams = { _parentId: this.parentId };
    }
    if (this.overrideAddButtonClick) {
      this.addButtonClick.emit(null);
    } else {
      this.router.navigate([this.router.url.slice(1).split('?')[0], 'add'], options);
    }
  }

  edit(item) {
    const options: NavigationExtras = {
      queryParams: { id: item.id },
    };
    this.router.navigate([this.router.url.slice(1).split('?')[0], 'edit'], options);
  }

  async delete(item) {
    if (window.confirm('Are you sure you want to delete this?')) {
      if (this.service) {
        await this.service.delete(item?.id);
      } else {
        await this.autoService.delete(this.formClass, this.api, item?.id);
      }
      this.getData();
    }
  }

  select = (item: T) => {
    const options: NavigationExtras = {
      queryParams: {
        _parentId: item.id,
      },
    };

    this.router.navigate([this.router.url.slice(1).split('?')[0]], options);
  };

  back = () => {
    if (this.parentId) {
      this.location.back();
    }
  };

  onItemSelected(event: T[]) {
    this.selected = event;
    this.itemSelect.emit(event);
  }

  filterChange(change: FilterChange) {
    this.filterMap = change.filter;
    this._idFilter = { ...this._idFilter, ...change.query };
    this.page = 0;
    this.fetchDataByUrl({
      [queryParams.PAGE]: this.page,
      ...this.filterChangeDif(change.filter),
      ...this.queryChangeDif(change.query),
    });
  }

  filterChangeDif(filter: Record<string, string[]>): Record<string, unknown> {
    Object.keys(this.filterHistory).forEach(key => {
      if (!key.includes(queryPlaceHolder)) {
        if (!filter[key]) {
          this.filterHistory[key] = null;
        } else {
          this.filterHistory[key] = filter[key];
        }
      }
    });
    this.filterHistory = { ...this.filterHistory, ...filter }; // Need to set when filterHistory is empty
    return this.filterHistory;
  }

  queryChangeDif(query: Record<string, string[]>): Record<string, unknown> {
    Object.keys(this.filterHistory).forEach(key => {
      if (key.includes(queryPlaceHolder)) {
        const queryKey = queryPlaceHolder + key;
        if (!query[queryKey]) {
          this.filterHistory[queryKey] = null;
        } else {
          this.filterHistory[queryKey] = query[key];
        }
      }
    });

    const convertedQuery = Object.keys(query)
      .map(key => {
        const placeHolderKey = queryPlaceHolder + key;
        if (query[key].length && !!query[key][0]['type'] && query[key][0]['type'] === 'date-range') {
          const dateRange = (query[key][0] as unknown) as DateRange;
          if (!!dateRange.from && !!dateRange.to) {
            return { [placeHolderKey]: [JSON.stringify(dateRange)] };
          }
          // Basically does the same thing as the previous forEach but the date-range doesn't come back as empty.
          // There are a lot of checks to look for a date, so just use it here.
          return { [placeHolderKey]: null };
        }
        return { [placeHolderKey]: query[key] };
      })
      .reduce((prev, cur) => ({ ...prev, ...cur }), {});

    this.filterHistory = { ...this.filterHistory, ...convertedQuery };
    return this.filterHistory;
  }

  orderChange(order: { column: string; direction: 'ASC' | 'DESC'; data: ColumnData<unknown> }) {
    this.orderColumn = order.data.orderKey;
    this.orderDirection = order.direction;
    this.fetchDataByUrl({
      [queryParams.ORDER_COLUMN]: this.orderColumn,
      [queryParams.ORDER_DIRECTION]: this.orderDirection,
    });
  }

  isObject(object): object is Object {
    return object != null && typeof object === 'object';
  }

  isObjectEqual(object1: Object, object2: Object) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = this.isObject(val1) && this.isObject(val2);
      if ((areObjects && !this.isObjectEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
        return false;
      }
    }

    return true;
  }

  onItemEdit(item: T) {
    const original = this.originalDataCopy.find(it => it.id === item.id);

    if (Object.keys(item).some(key => key === 'hasChanged' || key === 'isEditable' || key === 'newId')) {
      if (Object.keys(item).some(key => key === 'hasChanged')) {
        delete item['hasChanged'];
      }
      if (Object.keys(item).some(key => key === 'isEditable')) {
        delete item['isEditable'];
      }
      if (Object.keys(item).some(key => key === 'newId')) {
        delete item['newId'];
      }
      if (Object.keys(item).some(key => key === 'isSelected')) {
        delete item['isSelected'];
      }
    }

    if (!this.isObjectEqual(original, item)) {
      this.updatedItems = { [item.id]: { newItem: item, oldItem: original }, ...this.updatedItems };
      const updatedList = this.convertUpdatedItemsToArray(this.updatedItems);
      this.editedItems.emit(updatedList);
    }
  }

  convertUpdatedItemsToArray(data: Record<string, unknown>) {
    const results: T[] = [];
    for (const updatedItemsKey of Object.keys(data)) {
      const item = this.updatedItems[updatedItemsKey];
      results.push(item.newItem);
    }
    return results;
  }

  uploaded(uploaded: boolean) {
    if (uploaded) {
      this.getData();
      this.executedBulkUpload.emit();
    }
  }

  public setFilters(input: Record<string, unknown>): void {
    const change: FilterChange = {
      filter: {},
      query: {},
      search: {},
    };
    Object.entries(input).forEach(([key, value]) => {
      change.filter[key] = [`${value}`];
    });
    this.filterChange(change);
  }

  hasFilters(): boolean {
    return Object.keys(this.filterMap).length > 0;
  }
}
