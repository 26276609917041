import { Routes } from '@angular/router';
import { LiveFeedListComponent } from "./list/live-feed-list.component";


export const LiveFeedRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: LiveFeedListComponent
      }
    ]
  }
];
