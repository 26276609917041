import moment from 'moment';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import _ from 'lodash';
import { Color, Label } from 'ng2-charts';

export class DataChart {
  type?: ChartType;

  legend?: boolean;

  labels?: Label[];

  data?: ChartDataSets[];

  options?: ChartOptions;

  color?: Color[];

  average?: number;
}

interface ReturnData {
  label?: string;
  data?: number;
}

class TransformedData {
  label?: string[];

  data?: number[];

  max?: number;

  average?: number;

  constructor(label: string[], data: number[], max: number, average: number) {
    this.label = label;
    this.data = data;
    this.max = max;
    this.average = average;
  }
}

class DefaultDates {
  dateFrom?: Date;

  dateTo?: Date;

  constructor(dateFrom: Date, dateTo: Date) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
  }
}

export enum GraphType {
  BAR_GRAPH = 'bar',
  LINE_GRAPH = 'line',
}

export enum TimePeriod {
  YEARLY = 'Yearly',
  MONTHLY = 'Monthly',
  WEEKLY = 'Weekly',
  DAILY = 'Daily',
}

export enum GraphTitles {
  TOTAL_VEHICLES_DAILY = 'Vehicles Per Day',
  TOTAL_VEHICLES_WEEKLY = 'Vehicles Per Week',
  TOTAL_VEHICLES_MONTHLY = 'Vehicles Per Month',
  TOTAL_VEHICLES_YEARLY = 'Vehicles Per Year',

  YARD_ACTIVITY_DAILY = 'Daily Yard Activity',
  YARD_ACTIVITY_WEEKLY = 'Weekly Yard Activity',
  YARD_ACTIVITY_MONTHLY = 'Monthly Yard Activity',
  YARD_ACTIVITY_YEARLY = 'Yearly Yard Activity',
}

export class Utils {
  public static determineRange(dateFrom: string, dateTo: string) {
    const currentDateFrom = Utils.convertToDate(dateFrom);
    const currentDateTo = Utils.convertToDate(dateTo);
    const dayDifference = Math.abs(currentDateTo.diff(currentDateFrom, 'days'));
    if (currentDateFrom.format('YYYY') !== currentDateTo.format('YYYY')) {
      return TimePeriod.YEARLY;
    }
    if (dayDifference > currentDateFrom.daysInMonth()) {
      return TimePeriod.MONTHLY;
    }
    if (dayDifference > 8 && dayDifference <= currentDateFrom.daysInMonth()) {
      return TimePeriod.WEEKLY;
    }
    return TimePeriod.DAILY;
  }

  public static convertToDate(date: string) {
    return moment(date);
  }

  public static getDefaultDates() {
    const todayDateFrom = moment();
    const dateFrom = todayDateFrom.subtract(7, 'days');
    const dateTo = moment();
    return new DefaultDates(dateFrom.toDate(), dateTo.toDate());
  }

  public static validateDateRange(dateFrom: string, dateTo: string) {
    const currentDateFrom = Utils.convertToDate(dateFrom);
    const currentDateTo = Utils.convertToDate(dateTo);
    return currentDateFrom <= currentDateTo && currentDateTo <= moment();
  }

  public static transformData(response: ReturnData[]) {
    const label = _.map(response, item => item.label);
    const data = _.map(response, item => item.data);
    // eslint-disable-next-line max-len
    const average = data != null && data.length > 0 ? data.reduce((a, b) => a + b) / data.length : 0;
    // eslint-disable-next-line max-len
    const maxValue = data != null && data.length > 0 ? data.reduce((max, val) => (max > val ? max : val), data[0]) : 0;
    return new TransformedData(label, data, maxValue, average);
  }

  public static convertToChartDataOneSeriesNoLabels(data: number[]) {
    return [
      {
        data,
      },
    ];
  }
}

export class TotalVehiclesChart {
  public chart: DataChart;

  constructor(data: ReturnData[], dateTo: string, dateFrom: string) {
    const chart: DataChart = new DataChart();
    const chartData = Utils.transformData(data);
    chart.type = 'horizontalBar';
    chart.legend = false;
    chart.labels = chartData.label;
    chart.data = Utils.convertToChartDataOneSeriesNoLabels(chartData.data);
    chart.options = TotalVehiclesChart.generateChartOptions(dateTo, dateFrom, chartData.max);
    chart.average = chartData.average;
    chart.color = TotalVehiclesChart.chartColors();
    this.chart = chart;
  }

  private static generateChartOptions(dateTo: string, dateFrom: string, max: number) {
    const options: ChartOptions = {
      responsive: true,
      scales: {
        xAxes: [
          {
            scaleLabel: {
              fontStyle: 'extra-bold',
            },
            gridLines: {
              display: false,
            },
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
              fontSize: 11,
              fontStyle: 'bold',
              beginAtZero: true,
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Period',
              fontStyle: 'extra-bold',
            },
            ticks: {
              max: Math.ceil(max * 1.25),
              fontSize: 11,
              fontStyle: 'bold',
              beginAtZero: true,
            },
          },
        ],
      },
      elements: {
        line: {
          fill: false,
          tension: 0,
        },
        point: {
          radius: 4,
          hitRadius: 6,
          hoverRadius: 6,
          hoverBorderWidth: 6,
          backgroundColor: 'rgb(169, 229, 255)',
          borderColor: 'rgb(169, 229, 255)',
          borderWidth: 4,
        },
      },
      title: {
        text: this.generateTitle(Utils.determineRange(dateTo, dateFrom)),
        display: true,
        fontSize: 15,
        fontStyle: 'bold',
        padding: 15,
      },
      animation: {
        duration: 2000,
        easing: 'easeInOutQuart',
      },
    };
    return options;
  }

  private static generateTitle(indicator: string) {
    switch (indicator) {
      case TimePeriod.DAILY: {
        return GraphTitles.TOTAL_VEHICLES_DAILY;
      }
      case TimePeriod.WEEKLY: {
        return GraphTitles.TOTAL_VEHICLES_WEEKLY;
      }
      case TimePeriod.MONTHLY: {
        return GraphTitles.TOTAL_VEHICLES_MONTHLY;
      }
      case TimePeriod.YEARLY: {
        return GraphTitles.TOTAL_VEHICLES_YEARLY;
      }
      default: {
        return null;
      }
    }
  }

  private static chartColors(): Color[] {
    return [
      {
        backgroundColor: 'rgba(169, 229, 255)',
        borderColor: 'rgba(83, 168, 226, 0.7)',
        pointBackgroundColor: 'rgba(169, 229, 255, 0.5)',
        pointBorderColor: 'rgb(128, 212, 248)',
        pointHoverBackgroundColor: 'rgb(128, 212, 248)',
        pointHoverBorderColor: 'rgba(169, 229, 255, 0.5)',
      },
    ];
  }
}

export class SiteTurnAroundChart {
  public chart: DataChart;

  constructor(data: ReturnData[], dateTo: string, dateFrom: string) {
    const chart: DataChart = new DataChart();
    const chartData = Utils.transformData(data);
    chart.type = 'horizontalBar';
    chart.legend = false;
    chart.labels = chartData.label;
    chart.data = Utils.convertToChartDataOneSeriesNoLabels(chartData.data);
    chart.options = SiteTurnAroundChart.generateChartOptions(dateTo, dateFrom, chartData.max);
    chart.average = chartData.average;
    this.chart = chart;
  }

  public static generateChartOptions(dateTo: string, dateFrom: string, max: number) {
    const options: ChartOptions = {
      responsive: true,
      scales: {
        xAxes: [
          {
            scaleLabel: {
              fontStyle: 'extra-bold',
            },
            gridLines: {
              display: false,
            },
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
              fontSize: 11,
              fontStyle: 'bold',
              beginAtZero: true,
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Yard Sites',
              fontStyle: 'extra-bold',
            },
            ticks: {
              max: Math.ceil(max * 1.25),
              fontSize: 11,
              fontStyle: 'bold',
              beginAtZero: true,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
        },
      },
      title: {
        text: `Average Site Turn-Around Times(${Utils.determineRange(dateFrom, dateTo)})`,
        display: true,
        fontSize: 15,
        fontStyle: 'bold',
        padding: 15,
      },
      animation: {
        duration: 2000,
        easing: 'easeInOutQuart',
      },
    };
    return options;
  }
}

export class YardActivityChart {
  public chart: DataChart;

  constructor(data: ReturnData[], dateTo: string, dateFrom: string) {
    const chart: DataChart = new DataChart();
    const chartData = Utils.transformData(data);
    chart.type = GraphType.LINE_GRAPH;
    chart.legend = false;
    chart.labels = chartData.label;
    chart.data = Utils.convertToChartDataOneSeriesNoLabels(chartData.data);
    chart.options = YardActivityChart.generateChartOptions(dateTo, dateFrom, chartData.max);
    chart.color = YardActivityChart.yardActivityChartColors();
    chart.average = chartData.average;
    this.chart = chart;
  }

  public static generateChartOptions(dateTo: string, dateFrom: string, max: number) {
    const options: ChartOptions = {
      responsive: true,
      scales: {
        xAxes: [
          {
            scaleLabel: {
              fontStyle: 'extra-bold',
            },
            gridLines: {
              display: false,
            },
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
              fontSize: 11,
              fontStyle: 'bold',
              beginAtZero: true,
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: '# of Vehicles',
              fontStyle: 'extra-bold',
            },
            ticks: {
              max: Math.ceil(max * 1.25),
              fontSize: 11,
              fontStyle: 'bold',
            },
          },
        ],
      },
      elements: {
        point: {
          radius: 2,
          hitRadius: 2,
          hoverRadius: 7,
          hoverBorderWidth: 4,
          backgroundColor: 'rgb(169, 229, 255)',
          borderColor: 'rgba(169, 229, 255)',
          borderWidth: 2,
        },
      },
      title: {
        text: this.generateTitle(Utils.determineRange(dateTo, dateFrom)),
        display: true,
        fontSize: 15,
        fontStyle: 'bold',
        padding: 15,
      },
      animation: {
        duration: 2000,
        easing: 'easeInOutQuart',
      },
    };
    return options;
  }

  private static generateTitle(indicator: string) {
    switch (indicator) {
      case TimePeriod.DAILY: {
        return GraphTitles.YARD_ACTIVITY_DAILY;
      }
      case TimePeriod.WEEKLY: {
        return GraphTitles.YARD_ACTIVITY_WEEKLY;
      }
      case TimePeriod.MONTHLY: {
        return GraphTitles.YARD_ACTIVITY_MONTHLY;
      }
      case TimePeriod.YEARLY: {
        return GraphTitles.YARD_ACTIVITY_YEARLY;
      }
      default: {
        return null;
      }
    }
  }

  private static yardActivityChartColors(): Color[] {
    return [
      {
        backgroundColor: 'rgba(83, 168, 226, 0.1)',
        borderColor: 'rgba(83, 168, 226, 0.7)',
        pointBackgroundColor: 'rgba(169, 229, 255, 0.5)',
        pointBorderColor: 'rgb(128, 212, 248)',
        pointHoverBackgroundColor: 'rgb(128, 212, 248)',
        pointHoverBorderColor: 'rgba(169, 229, 255, 0.5)',
      },
    ];
  }
}

export class YardTurnAroundTimes {
  public chart: DataChart;

  constructor(data: ReturnData[], dateTo: string, dateFrom: string) {
    const chart: DataChart = new DataChart();
    const chartData = Utils.transformData(data);
    chart.type = 'horizontalBar';
    chart.legend = false;
    chart.labels = chartData.label;
    chart.data = Utils.convertToChartDataOneSeriesNoLabels(chartData.data);
    chart.options = YardTurnAroundTimes.generateChartOptions(dateTo, dateFrom, chartData.max);
    chart.color = YardTurnAroundTimes.yardTurnAroundChartColors();
    chart.average = chartData.average;
    this.chart = chart;
  }

  public static generateChartOptions(dateFrom: string, dateTo: string, max: number) {
    const options: ChartOptions = {
      responsive: true,
      scales: {
        xAxes: [
          {
            scaleLabel: {
              fontStyle: 'extra-bold',
            },
            gridLines: {
              display: false,
            },
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
              fontSize: 11,
              fontStyle: 'bold',
              beginAtZero: true,
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Period',
              fontStyle: 'extra-bold',
            },
            ticks: {
              beginAtZero: true,
              max: Math.ceil(max * 1.25),
              fontSize: 10,
              fontStyle: 'bold',
            },
          },
        ],
      },
      elements: {
        line: {
          fill: false,
          tension: 0,
        },
        point: {
          radius: 4,
          hitRadius: 6,
          hoverRadius: 6,
          hoverBorderWidth: 6,
          backgroundColor: 'rgb(169, 229, 255)',
          borderColor: 'rgb(169, 229, 255)',
          borderWidth: 4,
        },
      },
      title: {
        text: `Average Yard Turn-Around Times(${Utils.determineRange(dateFrom, dateTo)})`,
        display: true,
        fontSize: 15,
        fontStyle: 'bold',
        padding: 15,
      },
      animation: {
        duration: 2000,
        easing: 'easeInOutQuart',
      },
    };
    return options;
  }

  private static yardTurnAroundChartColors(): Color[] {
    return [
      {
        backgroundColor: 'rgba(169, 229, 255)',
        borderColor: 'rgba(83, 168, 226, 0.7)',
        pointBackgroundColor: 'rgba(169, 229, 255, 0.5)',
        pointBorderColor: 'rgb(128, 212, 248)',
        pointHoverBackgroundColor: 'rgb(128, 212, 248)',
        pointHoverBorderColor: 'rgba(169, 229, 255, 0.5)',
      },
    ];
  }
}
