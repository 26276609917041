import {
  OVEntity,
  OVField,
  OVForm,
  OVSearchable,
  OVSidebar,
  OVTable
} from '@ov-suite/ov-metadata';

@OVEntity('ExitReason', 'yardlink')
@OVForm([['#Exit Reason'], ['value']])
@OVSidebar([['id']])
@OVTable<ExitReason>([
  {
    key: 'value',
    title: 'Reason',
    type: 'string'
  }
])
export class ExitReason {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Reason',
    placeholder: 'Required',
    required: true
  })
  value: string;
}
