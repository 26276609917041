import {
  OVField,
  OVTable,
  OVSearchable,
  OVEntity
} from '@ov-suite/ov-metadata';

@OVEntity('Unit', 'yardlink')
@OVTable<Unit>([
  {
    key: 'id',
    title: 'Id',
    type: 'string'
  },
  {
    key: 'unitId',
    title: 'UnitId',
    type: 'string'
  },
  {
    key: 'packingStart',
    title: 'Packing Start',
    type: 'string'
  },
  {
    key: 'packingFinish',
    title: 'Packing Finish',
    type: 'string'
  },
  {
    key: 'counter',
    title: 'Counter',
    type: 'string'
  },
  {
    key: 'packingSupervisor',
    title: 'Packing Supervisor',
    type: 'string'
  },
  {
    key: 'forkliftDriverPutAway',
    title: 'Forklift Driver PutAway',
    type: 'string'
  },
  {
    key: 'timeOfPutAway',
    title: 'Time of PutAway',
    type: 'string'
  },
  {
    key: 'quantity',
    title: 'Quantity',
    type: 'number'
  }
])
export class Unit {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Unit ID',
    placeholder: 'Required',
    required: true
  })
  unitId: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Packing Start',
    placeholder: 'Required',
    required: true
  })
  packingStart: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Packing Finish',
    placeholder: 'Required',
    required: true
  })
  packingFinish: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Counter',
    placeholder: 'Required',
    required: true
  })
  counter: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Packing Supervisor',
    placeholder: 'Required',
    required: true
  })
  packingSupervisor: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Time of PutAway',
    placeholder: 'Required',
    required: true
  })
  timeOfPutAway: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Forklift Driver PutAway',
    placeholder: 'Required',
    required: true
  })
  forkliftDriverPutAway: string;

  @OVField({
    type: 'number',
    title: 'Quantity'
  })
  quantity: number;
}
