import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { OvAutoService } from '@ov-suite/services';
import { UiModule } from '../ui.module';
import { OvGenericListComponent } from './list/ov-generic-list.component';
import { OvGenericAddOrEditComponent } from './add-or-edit/ov-generic-add-or-edit.component';
import { OvGenericRoutes } from './ov-generic.routing';
import { OvGenericModuleParams } from './ov-generic.types';
import { OvGenericGuard } from './ov-generic.guard';

@NgModule({
  imports: [CommonModule, RouterModule.forChild(OvGenericRoutes), FormsModule, UiModule],
  declarations: [OvGenericListComponent, OvGenericAddOrEditComponent],
  providers: [OvAutoService, OvGenericGuard],
})
export class OvGenericModule {
  static forRoot(options: OvGenericModuleParams): ModuleWithProviders<OvGenericModule> {
    const defaultOptions: OvGenericModuleParams = {
      onCreate: () => null,
      onDelete: () => null,
      onUpdate: () => null,
      ...options,
    };

    return {
      ngModule: OvGenericModule,
      providers: [{ provide: 'ENTITY_OPTIONS', useValue: defaultOptions }],
    };
  }
}
