import { Routes } from '@angular/router';
import { StatisticsDashboardComponent } from "./dashboard/statistics-dashboard.component";

export const StatisticsRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: StatisticsDashboardComponent
      }
    ]
  }
];
