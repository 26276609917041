import { Component, OnInit } from '@angular/core';
import { ExitEntryEvent } from '@ov-suite/models-yard';
import { HierarchyTab } from '@ov-suite/ui';
import { ExitEntryEventService } from '../../../services/exit-entry-event/exit-entry-event.service';

@Component({
  selector: 'ov-suite-arrivals-list',
  templateUrl: './arrivals-list.component.html',
  styleUrls: ['./arrivals-list.component.scss'],
})
export class ArrivalsListComponent implements OnInit {
  // Class - Required
  formClass = ExitEntryEvent;

  filter: Record<string, string[]>;

  ngOnInit(): void {
    this.filter = { entryUser: [null] };
  }

  constructor(public exitEntryEventService: ExitEntryEventService) {}
}
