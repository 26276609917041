import { OVField, OVForm, OVSearchable, OVEntity } from '@ov-suite/ov-metadata';

@OVEntity({ name: 'ProductType', tableName: 'product_type' })
@OVForm([['#Product Type Details'], ['name', 'description']])

export class ProductType {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVField({ type: 'string', title: 'Description', required: false })
  description?: string;

  @OVField({ type: 'boolean', title: 'Active', required: false })
  active?: string;
}
