import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { StakeholderLocation } from '@ov-suite/models-yard';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: StakeholderLocation,
      api: 'yardlink',
      createTitle: 'Stakeholder Location',
      route: ['stakeholder-location'],
      tableTitle: 'Stakeholder Locations',
      showFiller: false,
    }),
  ],
})
export class StakeholderLocationModule {}
