import { NgModule } from '@angular/core';
import { UiModule, AdminLayoutModule, GraphQLModule } from '@ov-suite/ui';
import { JwtInterceptor } from '@ov-suite/authguard-angular';
import { environment } from '@ov-suite/helpers-shared';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from '@ov-suite/helpers-angular';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { angularRoutes, sidebarRoutes } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent, AuthLayoutComponent],
  imports: [
    AdminLayoutModule.forRoot({
      projectName: 'Yard Link',
      sidebarRoutes,
      angularRoutes,
    }),
    UiModule.forRoot({
      apiUrl: environment.apiUrl.yard,
      imageUploadUrl: environment.other.awsFileServiceApi,
    }),
    SharedModule,
    GraphQLModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: 'DEFAULT_API',
      useValue: 'yardlink',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
