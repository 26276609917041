import { Routes } from '@angular/router';
import { EnteredDepartedCollectionsListComponent } from './list/entered-departed-collections-list.component';

export const EnteredDepartedCollectionsRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: EnteredDepartedCollectionsListComponent,
      },
    ],
  },
];
