<div class="graph-container" *ngIf="+domainId > 0">
    <div class="main-container container-fluid slide-in-rightp-0">
      <div class="dashboardContainer">
        <div class="title">
          <p class="statisticsHeader">Dashboard Statistics</p>
        </div>
        <div class="">
          <ov-suite-input
            class="d-inline-block"
            id="dateFrom"
            type="date"
            [(ngModel)]="dateFrom"
            (ngModelChange)="validate(dateFrom, dateTo)"
            [showTime]="false"
          >
          </ov-suite-input>
          <ov-suite-input
            id="dateTo"
            type="date"
            [(ngModel)]="dateTo"
            (ngModelChange)="validate(dateFrom, dateTo)"
            [showTime]="false"
            class="ml-2 d-inline-block"
          >
          </ov-suite-input>
        </div>
      </div>

      <div class="container-fluid">
        <div class="chart-container">
          <div class="canvasContainer card-body left">
              <div *ngIf="!totalVehiclesLoaded">
                <div class="graphsLoader">
                  <div class="Bar"></div>
                  <div class="Bar"></div>
                  <div class="Bar"></div>
                  <div class="Bar"></div>
                  <div class="Bar"></div>
                  <div class="Bar"></div>
                </div>
              </div>
              <div *ngIf="totalVehiclesLoaded">
                <canvas #vehicleTotalChart baseChart
                          [datasets]="totalVehiclesChart.chart.data"
                          [labels]="totalVehiclesChart.chart.labels"
                          [options]="totalVehiclesChart.chart.options"
                          [legend]="totalVehiclesChart.chart.legend"
                          [chartType]="totalVehiclesChart.chart.type"
                          [colors]="totalVehiclesChart.chart.color">
                </canvas>
              </div>
          </div>
          <div class="canvasContainer card-body right">
            <div *ngIf="!yardActivityChartLoaded">
              <div class="graphsLoader">
                <div class="Bar"></div>
                <div class="Bar"></div>
                <div class="Bar"></div>
                <div class="Bar"></div>
                <div class="Bar"></div>
                <div class="Bar"></div>
              </div>
            </div>
            <div *ngIf="yardActivityChartLoaded">
              <canvas baseChart
                      [datasets]="yardActivityChart.chart.data"
                      [labels]="yardActivityChart.chart.labels"
                      [options]="yardActivityChart.chart.options"
                      [legend]="yardActivityChart.chart.legend"
                      [chartType]="yardActivityChart.chart.type"
                      [colors]="yardActivityChart.chart.color">
              </canvas>
            </div>
          </div>

          <div class="canvasContainer card-body left">
            <div *ngIf="!yardTurnAroundTimesLoaded">
              <div class="graphsLoader">
                <div class="Bar"></div>
                <div class="Bar"></div>
                <div class="Bar"></div>
                <div class="Bar"></div>
                <div class="Bar"></div>
                <div class="Bar"></div>
              </div>
            </div>
            <div *ngIf="yardTurnAroundTimesLoaded">
              <canvas baseChart
                      [datasets]="yardTurnAroundChart.chart.data"
                      [labels]="yardTurnAroundChart.chart.labels"
                      [options]="yardTurnAroundChart.chart.options"
                      [legend]="yardTurnAroundChart.chart.legend"
                      [chartType]="yardTurnAroundChart.chart.type"
                      [colors]="yardTurnAroundChart.chart.color">
              </canvas>
            </div>
          </div>

          <div class="canvasContainer card-body right">
            <div *ngIf="!siteTurnAroundTimesLoaded">
              <div class="graphsLoader">
                <div class="Bar"></div>
                <div class="Bar"></div>
                <div class="Bar"></div>
                <div class="Bar"></div>
                <div class="Bar"></div>
                <div class="Bar"></div>
              </div>
            </div>
            <div *ngIf="siteTurnAroundTimesLoaded">
              <canvas #siteTurnaroundChart baseChart
                      [datasets]="siteTurnAroundChart.chart.data"
                      [labels]="siteTurnAroundChart.chart.labels"
                      [options]="siteTurnAroundChart.chart.options"
                      [legend]="siteTurnAroundChart.chart.legend"
                      [chartType]="siteTurnAroundChart.chart.type"
                      [colors]="siteTurnaroundChartColors">
              </canvas>
            </div>
          </div>
        </div>

        <div class="col-xl-12 no-gutters averageSeparator"></div>
          <div [hidden]="!totalVehiclesLoaded
          && !yardTurnAroundTimesLoaded
          && !yardActivityChartLoaded
          && !siteTurnAroundTimesLoaded" class="row widget-container">
            <div class="text-center col-xl-3">
              <div class="averageDisplayContainer" >
                <div class="averageDisplayDescription">
                  <div class="averageDisplayHeader">
                    {{totalVehiclesChart ? totalVehiclesChart?.chart?.average?.toFixed(2) : 0}}
                  </div>
                  <div class="averageDisplayText">Product Deliveries</div>
                </div>
                <div class="averageDisplayIcon">
                  <i class="averageIcon" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-box"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                  </i>
                </div>
              </div>
            </div>

              <div class="text-center col-xl-3">
                <div class="averageDisplayContainer">
                  <div class="averageDisplayDescription">
                    <div class="averageDisplayHeader">{{yardActivityChart ? yardActivityChart?.chart?.average?.toFixed(2) : 0}}</div>
                    <div class="averageDisplayText">Avg Vehicles Per Day</div>
                  </div>
                  <div class="averageDisplayIcon">
                    <i class="averageIcon" aria-hidden="true">
                      <svg version="1.1"
                           id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                           x="0px" y="0px" width="44px" height="44px"
                           viewBox="0 0 39.055 39.054" style="enable-background:new 0 0 39.055 39.054;" xml:space="preserve">
	                          <g>
		                          <path
                                d="M38.831,14.26c-0.191-0.233-0.476-0.369-0.775-0.369h-3.801c-0.938-2.474-2.16-4.898-3.549-5.813
			                        c-4.805-3.161-17.55-3.161-22.355,0c-1.39,0.916-2.607,3.343-3.55,5.813H1c-0.302,0-0.586,0.136-0.775,0.369
			                        c-0.19,0.232-0.266,0.539-0.204,0.834l0.563,2.728c0.096,0.465,0.506,0.797,0.979,0.797h1.126
			                        c-1.087,1.254-1.614,2.833-1.621,4.413c-0.007,1.952,0.734,3.716,2.089,4.964c0.015,0.013,0.03,0.022,0.044,0.035v3.817
			                        c0,0.827,0.672,1.5,1.5,1.5h3.506c0.828,0,1.5-0.673,1.5-1.5v-1.534h19.641v1.534c0,0.827,0.672,1.5,1.5,1.5h3.506
			                        c0.826,0,1.5-0.673,1.5-1.5v-3.742c1.438-1.317,2.125-3.129,2.134-4.938c0.006-1.634-0.545-3.271-1.696-4.551h1.201
			                        c0.475,0,0.885-0.332,0.979-0.798l0.564-2.727C39.094,14.799,39.021,14.494,38.831,14.26z M9.998,10.583
			                        c3.83-2.521,15.229-2.521,19.057,0c0.744,0.488,1.701,2.461,2.578,4.877H7.422C8.297,13.045,9.254,11.073,9.998,10.583z
			                        M5.512,23.408c0-1.63,1.322-2.95,2.951-2.95c1.631,0,2.951,1.32,2.951,2.95s-1.32,2.951-2.951,2.951
			                        C6.834,26.359,5.512,25.038,5.512,23.408z M30.631,26.359c-1.629,0-2.951-1.321-2.951-2.951s1.322-2.95,2.951-2.95
			                        c1.631,0,2.951,1.32,2.951,2.95S32.26,26.359,30.631,26.359z"/>
	                          </g>
                      </svg>
                    </i>
                  </div>
                </div>
              </div>

              <div class="text-center col-xl-3">
                <div class="averageDisplayContainer">
                  <div class="averageDisplayDescription">
                    <div class="averageDisplayHeader">
                      {{yardTurnAroundChart ? yardTurnAroundChart?.chart?.average?.toFixed(2) : 0}} hrs
                    </div>
                    <div class="averageDisplayText">Avg Yard Time</div>
                  </div>
                  <div class="averageDisplayIcon">
                    <i class="averageIcon" aria-hidden="true">
                      <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                    </i>
                  </div>
                </div>
              </div>

              <div class="text-center col-xl-3">
                <div class="averageDisplayContainer">
                  <div class="averageDisplayDescription">
                    <div class="averageDisplayHeader">
                      {{siteTurnAroundChart ? siteTurnAroundChart?.chart?.average?.toFixed(2) : 0}} mins
                    </div>
                    <div class="averageDisplayText">Avg Site Time</div>
                  </div>
                  <div class="averageDisplayIcon">
                    <i class="averageIcon" aria-hidden="true">
                      <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-anchor"><circle cx="12" cy="5" r="3"></circle><line x1="12" y1="22" x2="12" y2="8"></line><path d="M5 12H2a10 10 0 0 0 20 0h-3"></path></svg>
                    </i>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
</div>
