import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { AutoService, OvAutoService } from '@ov-suite/services';
import {
  Constructor,
  FieldMetadata,
  FieldParamsConstructor,
  GenericHierarchy,
  getFieldMetadata,
  getSearchableMetadata,
  getTypeMetadata,
  SearchableMetadata,
} from '@ov-suite/ov-metadata';
import { HasId } from '@ov-suite/ui';
import { AbstractValueAccessor, MakeProvider } from '../input/abstruct-value-accessor';

type GenericHierarchyType = GenericHierarchy;

export interface WithQuantity<T> {
  quantity: number;
  [key: string]: T | number;
}

@Component({
  selector: 'ov-suite-tree-select',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss'],
  providers: [MakeProvider(TreeSelectComponent)],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TreeSelectComponent<T extends GenericHierarchyType>
  extends AbstractValueAccessor<T | T[] | WithQuantity<T> | WithQuantity<T>[] | (T | WithQuantity<T>)[]>
  implements OnInit {
  @Input() title = '';

  @Input() tooltip = '';

  @Input() danger = false;

  @Input() nameColumnLabel = 'Name';

  @Input() nameColumnKey = 'name';

  @Input() flat = false;

  @Input() single = false;

  hasAll = false;

  _subItem: FieldParamsConstructor;

  @Input() set subItem(subItem: FieldParamsConstructor) {
    this._subItem = subItem;
    this.limit = this._subItem.dropdownLimit;
  }

  private _withQuantity = false;

  @Input() set withQuantity(input: boolean) {
    this._withQuantity = !!input;
    if (this.metadata) {
      this.quantityKey = this.metadata.fields.find(item => item.propertyKey !== 'quantity' && item.propertyKey !== 'id')?.propertyKey;
    }
  }

  get withQuantity() {
    return this._withQuantity;
  }

  get multiValue() {
    if (Array.isArray(this.val)) {
      return this.val;
    }
    if (!this.val) {
      return [];
    }
    return [this.val];
  }

  limit = 100;

  name: string;

  _formClass: Constructor;

  quantityKey: string;

  @Input() set formClass(formClass: Constructor | Constructor[]) {
    this._formClass = Array.isArray(formClass) ? formClass[0] : formClass;
    if (formClass) {
      this.metadata = getFieldMetadata(this._formClass);
      this.name = this.metadata.name;
      if (this.withQuantity) {
        this.quantityKey = this.metadata.fields.find(item => item.propertyKey !== 'quantity' && item.propertyKey !== 'id').propertyKey;
      }
    }
    if (this.name) {
      this.searchableMetadata = getSearchableMetadata(this._formClass);
    }
  }

  get formClass() {
    return this._formClass;
  }

  metadata: FieldMetadata;

  searchableMetadata: SearchableMetadata;

  currentParentId: number | string = null;

  _currentData: T[] = [];

  set currentData(data: T[]) {
    this._currentData = data;
    if (data?.length) {
      this.currentTitle = data[0].parent?.name ?? 'All';
    }
    this.calculateSelection();
  }

  get currentData() {
    return this._currentData;
  }

  currentTitle = this.title;

  filteredPath: number[] = [];

  selectedData: (T | WithQuantity<T>)[] = [];

  searchValue: string;

  searchQuery: Record<string, string[]>;

  selectionMap: {
    [key: number]: 'selected' | 'not' | 'partial' | 'unavailable';
  } = {};

  _ovAutoService: OvAutoService;

  @Input() set ovAutoService(service: OvAutoService) {
    this._ovAutoService = service;
    if (service) {
      this.reset();
    }
  }

  get ovAutoService() {
    return this._ovAutoService;
  }

  _service: AutoService<T>;

  @Input() set service(service: AutoService<T>) {
    this._service = service;
    if (service) {
      this.reset();
    }
  }

  get service() {
    return this._service;
  }

  constructor(@Inject('DEFAULT_API') private readonly defaultApi: string) {
    super();
  }

  ngOnInit() {}

  calculateSelection() {
    this.currentData?.forEach(item => {
      this.selectionMap[item.id] = this.getSelectionType(item);
    });
  }

  getSelectionType(item: T): 'selected' | 'not' | 'partial' | 'unavailable' {
    if (this.flat) {
      return this.multiValue.some(entry => entry.id === item.id) ? 'selected' : 'not';
    }
    const path = item.path.slice(0, -1).split('.');
    path.pop();
    const unavailable = this.multiValue.some(entry => path.includes(entry.id.toString()));
    if (unavailable) {
      return 'unavailable';
    }
    const found = this.multiValue.filter(entry =>
      this.withQuantity
        ? ((entry as WithQuantity<T>)[this.quantityKey] as T).path.includes(`${item.id}.`)
        : (entry as T).path.includes(`${item.id}.`),
    );
    if (found?.length) {
      if (found.some(entry => entry.id === item.id)) {
        return 'selected';
      }
      return 'partial';
    }
    return 'not';
  }

  writeValue(value: T | T[] | WithQuantity<T> | WithQuantity<T>[] | (T | WithQuantity<T>)[]) {
    this.val = value;
    this.onChange(value);
    this.calculateSelection();
  }

  getSearch(input?: number | string): Record<string, string[]> {
    return this.flat ? {} : { parentId: [input ? input.toString() : null] };
  }

  async select(item: T) {
    if (!this.flat && this.selectionMap[item.id] === 'not') {
      this.filteredPath = item.path
        .slice(0, -1)
        .split('.')
        .map(entry => Number(entry));
      this.currentTitle = item.name;
      this.searchValue = '';
      this.searchQuery = {};
      if (this.service) {
        this.service
          .list({
            limit: this.limit,
            query: this.getSearch(item.id),
          })
          .then(response => {
            this.currentData = response.data;
            this.currentParentId = item.id;
          });
      } else {
        let { api } = this.metadata;
        if (!api || api === 'shared') {
          api = this.defaultApi;
        }
        this.ovAutoService
          .list({
            entity: this.formClass as Constructor<HasId>,
            limit: this.limit,
            query: this.getSearch(item.id),
            specifiedApi: api,
          })
          .then(response => {
            this.currentData = response.data as T[];
            this.currentParentId = item.id;
          });
      }
    }
  }

  async back() {
    if (this.flat) {
      return;
    }
    this.filteredPath.pop();
    const parentId = this.filteredPath.length ? this.filteredPath[this.filteredPath.length - 1] : undefined;
    if (this.service) {
      this.service
        .list({
          search: {}, // this.getSearch(parentId),
          query: this.getSearch(parentId),
          limit: this.limit,
          offset: 0,
        })
        .then(response => {
          this.currentData = response.data;
          this.currentParentId = parentId ?? null;
        });
    } else {
      let { api } = this.metadata;
      if (!api || api === 'shared') {
        api = this.defaultApi;
      }
      this.ovAutoService
        .list({
          entity: this.formClass as Constructor<{ id: string | number }>,
          specifiedApi: api,
          search: {}, // this.getSearch(parentId),
          query: this.getSearch(parentId),
          limit: this.limit,
          offset: 0,
        })
        .then(response => {
          this.currentData = response.data as T[];
          this.currentParentId = parentId ?? null;
        });
    }
  }

  async reset() {
    if (this.service) {
      this.service
        .list({
          search: {}, // this.getSearch(parentId),
          query: this.getSearch(),
          limit: this.limit,
          offset: 0,
        })
        .then(response => {
          this.currentData = response.data;
        });
    } else {
      const subItemType = this._subItem.withQuantity ? this._subItem.subType : this._subItem.type;
      const { entity } = getTypeMetadata(subItemType);
      let { api } = this.metadata;
      if (!api || api === 'shared') {
        api = this.defaultApi;
      }
      this.ovAutoService
        .list({
          entity,
          specifiedApi: api,
          search: {}, // this.getSearch(parentId), // todo fix this page
          filter: this.searchQuery,
          query: this.getSearch(),
          limit: this.limit,
          offset: 0,
        })
        .then(response => {
          this.currentData = response.data as T[];
        });
    }
  }

  onSearchChange(event: Event): void {
    if ((event?.target as HTMLInputElement)?.value) {
      this.search((event.target as HTMLInputElement).value);
    } else {
      this.searchQuery = {};
      this.reset();
    }
  }

  search(text: string): void {
    let newFilter = {};
    this.searchableMetadata.fields.forEach(field => {
      const key = `${this.name}.${field.propertyKey}`;
      if (newFilter) {
        newFilter = { ...newFilter, [key]: [text] };
      } else {
        newFilter = { [key]: [text] };
      }
    });

    if (text && Object.keys(newFilter).length < 1 && this.searchableMetadata.fields.length < 1) {
      const key = `${this.quantityKey}.${this.nameColumnKey || 'name'}`;
      newFilter = { [key]: [text] };
    }

    this.searchQuery = newFilter;
    this.reset();
  }

  async cautionAdd(event, item: T) {
    event?.stopPropagation();
    if (item && confirm('This will replace other items further down the tree')) {
      await this.add(event, item);
    }
  }

  async add(event, item: T) {
    event?.stopPropagation();
    let actualItem: T | WithQuantity<T> = item;

    if (this.withQuantity) {
      actualItem = { quantity: 1, [this.quantityKey]: item };
    }

    if (this.single) {
      this.writeValue(actualItem);
      return;
    }
    this.selectedData.push(actualItem);
    let val;
    if (!this.val) {
      val = [actualItem];
    } else if (this.flat) {
      val = [...this.multiValue, actualItem];
    } else {
      val = [
        ...this.multiValue.filter(existing =>
          this.withQuantity
            ? !existing[this.quantityKey].path.includes(`${actualItem.id}.`)
            : !(existing as T).path.includes(`${actualItem.id}.`),
        ),
        actualItem,
      ];
    }

    this.writeValue(val);
  }

  addAll() {
    if (this.flat) {
      let val;
      if (!this.hasAll) {
        this.selectedData = this.currentData;
        val = [...this.currentData];
      } else {
        this.selectedData = [];
        val = [];
      }
      this.writeValue(val);
    }
    this.hasAll = !this.hasAll;
  }

  remove(event, item: T | WithQuantity<T>) {
    event?.stopPropagation();
    if (this.single) {
      this.writeValue(null);
      return;
    }
    this.writeValue(
      this.multiValue.filter(selected =>
        this.withQuantity
          ? ((selected as WithQuantity<T>)[this.quantityKey] as T)?.id !== ((item as WithQuantity<T>)[this.quantityKey] as T)?.id
          : (selected as T).id !== (item as T).id,
      ),
    );
  }

  forceUpdate() {
    this.onChange(this.value);
  }
}
