import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { GeofenceRoutes } from './geofence.routing';
import { GeofenceListComponent } from './list/geofence-list.component';
import { GeofenceAddOrEditComponent } from './add-or-edit/geofence-add-or-edit.component';
import { UserService } from '../../../../../idmlink-web/src/app/services/user/user.service';
import { ModalComponent } from '../../shared/components/modal/modal.component';
import { FilterPipe } from '../../shared/pipes/filter.pipe';

@NgModule({
  imports: [CommonModule, RouterModule.forChild(GeofenceRoutes), FormsModule, UiModule, LeafletModule, LeafletDrawModule],
  declarations: [GeofenceListComponent, GeofenceAddOrEditComponent, ModalComponent, FilterPipe],
  providers: [UserService],
})
export class GeofenceModule {}
