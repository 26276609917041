import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { BaseChartDirective, Color } from 'ng2-charts';
import moment from 'moment';
import {
  SiteTurnAroundChart,
  TimePeriod,
  TotalVehiclesChart,
  Utils,
  YardActivityChart,
  YardTurnAroundTimes,
} from './statistics-dashboard.constants';
import { ReportsServiceExitEntry, ReportsServiceVehicleTrack } from '../../../services/reports/reports.service';
import { SessionStorage } from "@ov-suite/helpers-angular";

@Component({
  selector: 'ov-suite-statistics-dashboard',
  templateUrl: './statistics-dashboard.component.html',
  styleUrls: ['./statistics-dashboard.component.scss'],
})
export class StatisticsDashboardComponent implements OnInit {
  @ViewChild('vehicleTotalChart') canvasTotal: ElementRef;

  @ViewChild('siteTurnaroundChart') canvasTurnaround: ElementRef;

  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;

  dateFrom: Date;

  dateTo: Date;

  totalVehiclesChart: TotalVehiclesChart;

  totalVehiclesLoaded = false;

  yardTurnAroundChart: YardTurnAroundTimes;

  yardTurnAroundTimesLoaded = false;

  siteTurnAroundChart: SiteTurnAroundChart;

  siteTurnAroundTimesLoaded = false;

  siteTurnaroundChartColors: Color[];

  yardActivityChart: YardActivityChart;

  yardActivityChartLoaded = false;

  domainId: number;

  constructor(
    private readonly reportsServiceExitEntry: ReportsServiceExitEntry,
    private readonly reportsServiceVehicleTrack: ReportsServiceVehicleTrack,
  ) {}

  ngOnInit() {
    this.getDefaultDates();
    this.domainId = +SessionStorage.getSelectedDomain() ?? null;
  }

  getDefaultDates() {
    const defaultDates = Utils.getDefaultDates();
    this.dateFrom = defaultDates.dateFrom;
    this.dateTo = defaultDates.dateTo;
  }

  validate(dateFrom: Date, dateTo: Date) {
    const dateFromString = moment(dateFrom).format('YYYY-MM-DD');
    const dateToString = moment(dateTo).format('YYYY-MM-DD');

    console.log(dateFromString);
    console.log(dateToString);

    // if (Utils.validateDateRange(dateFromString, dateToString)) {
    this.generateCharts(dateFromString, moment(dateToString).add(1, 'days').format('YYYY-MM-DD'));
    this.refreshCharts();
    //   // if (this.siteTurnAroundTimesLoaded && this.totalVehiclesLoaded) {
    //   //   this.generateGradients();
    //   // }
    // } else {
    //   this.getDefaultDates();
    // }
  }

  generateCharts(dateFrom: string, dateTo: string) {
    this.generateVehiclesTotalChart(dateFrom, dateTo);
    this.generateSiteTurnAroundTimesChart(dateFrom, dateTo);
    this.generateYardTurnAroundTimesChart(dateFrom, dateTo);
    this.generateYardActivityChart(dateFrom, dateTo);
  }

  refreshCharts() {
    this.charts.forEach(chart => {
      chart.update();
    });
  }

  generateVehiclesTotalChart(dateFrom: string, dateTo: string) {
    const type = Utils.determineRange(dateFrom, dateTo);

    switch (type) {
      case TimePeriod.DAILY: {
        this.reportsServiceExitEntry.getVehiclesPerDay(dateFrom, dateTo).subscribe(
          // tslint:disable-next-line:no-any
          (res: any) => {
            const data = res.data.getVehiclesPerDay;
            this.totalVehiclesChart = new TotalVehiclesChart(data, dateFrom, dateTo);
            this.totalVehiclesLoaded = true;
            // this.addGradientToTotalVehicleChart();
          },
        );
        break;
      }
      case TimePeriod.WEEKLY: {
        this.reportsServiceExitEntry.getVehiclesPerWeek(dateFrom, dateTo).subscribe(
          // tslint:disable-next-line:no-any
          (res: any) => {
            const data = res.data.getVehiclesPerWeek;
            this.totalVehiclesChart = new TotalVehiclesChart(data, dateFrom, dateTo);
            this.totalVehiclesLoaded = true;
            // this.addGradientToTotalVehicleChart();
          },
        );
        break;
      }
      case TimePeriod.MONTHLY: {
        this.reportsServiceExitEntry.getVehiclesPerMonth(dateFrom, dateTo).subscribe(
          // tslint:disable-next-line:no-any
          (res: any) => {
            const data = res.data.getVehiclesPerMonth;
            this.totalVehiclesChart = new TotalVehiclesChart(data, dateFrom, dateTo);
            this.totalVehiclesLoaded = true;
            // this.addGradientToTotalVehicleChart();
          },
        );
        break;
      }
      case TimePeriod.YEARLY: {
        this.reportsServiceExitEntry.getVehiclesPerYear(dateFrom, dateTo).subscribe(
          // tslint:disable-next-line:no-any
          (res: any) => {
            const data = res.data.getVehiclesPerYear;
            this.totalVehiclesChart = new TotalVehiclesChart(data, dateFrom, dateTo);
            this.totalVehiclesLoaded = true;
            // this.addGradientToTotalVehicleChart();
          },
        );
        break;
      }
      default: {
        break;
      }
    }
  }

  generateYardTurnAroundTimesChart(dateFrom: string, dateTo: string) {
    const type = Utils.determineRange(dateFrom, dateTo);

    switch (type) {
      case TimePeriod.DAILY: {
        this.reportsServiceExitEntry.getAverageYardTurnAroundTimesPerDay(dateFrom, dateTo).subscribe(
          // tslint:disable-next-line:no-any
          (res: any) => {
            const data = res.data.getAverageYardTurnAroundTimesPerDay;
            this.yardTurnAroundChart = new YardTurnAroundTimes(data, dateFrom, dateTo);
            this.yardTurnAroundTimesLoaded = true;
          },
        );
        break;
      }
      case TimePeriod.WEEKLY: {
        this.reportsServiceExitEntry.getAverageYardTurnAroundTimesPerWeek(dateFrom, dateTo).subscribe(
          // tslint:disable-next-line:no-any
          (res: any) => {
            const data = res.data.getAverageYardTurnAroundTimesPerWeek;
            this.yardTurnAroundChart = new YardTurnAroundTimes(data, dateFrom, dateTo);
            this.yardTurnAroundTimesLoaded = true;
          },
        );
        break;
      }
      case TimePeriod.MONTHLY: {
        this.reportsServiceExitEntry.getAverageYardTurnAroundTimesPerMonth(dateFrom, dateTo).subscribe(
          // tslint:disable-next-line:no-any
          (res: any) => {
            const data = res.data.getAverageYardTurnAroundTimesPerMonth;
            this.yardTurnAroundChart = new YardTurnAroundTimes(data, dateFrom, dateTo);
            this.yardTurnAroundTimesLoaded = true;
          },
        );
        break;
      }
      case TimePeriod.YEARLY: {
        this.reportsServiceExitEntry.getAverageYardTurnAroundTimesPerYear(dateFrom, dateTo).subscribe(
          // tslint:disable-next-line:no-any
          (res: any) => {
            const data = res.data.getAverageYardTurnAroundTimesPerYear;
            this.yardTurnAroundChart = new YardTurnAroundTimes(data, dateFrom, dateTo);
            this.yardTurnAroundTimesLoaded = true;
          },
        );
        break;
      }
      default: {
        break;
      }
    }
  }

  generateSiteTurnAroundTimesChart(dateFrom: string, dateTo: string) {
    this.reportsServiceVehicleTrack.getAverageSiteTurnAroundTimes(dateFrom, dateTo).subscribe(
      // tslint:disable-next-line:no-any
      (res: any) => {
        const data = res.data.getAverageSiteTurnAroundTimes;
        this.siteTurnAroundChart = new SiteTurnAroundChart(data, dateTo, dateFrom);
        this.siteTurnAroundTimesLoaded = true;
        this.addGradientToSiteTurnaroundChart();
      },
    );
  }

  generateYardActivityChart(dateFrom: string, dateTo: string) {
    this.reportsServiceExitEntry.getExitEntryEvents(dateFrom, dateTo).subscribe(
      // tslint:disable-next-line:no-any
      (res: any) => {
        const data = res.data.getExitEntryEvents;
        this.yardActivityChart = new YardActivityChart(data, dateFrom, dateTo);
        this.yardActivityChartLoaded = true;
      },
    );
  }

  // generateGradients() {
  //   this.addGradientToSiteTurnaroundChart();
  //   this.addGradientToTotalVehicleChart();
  // }

  // addGradientToTotalVehicleChart() {
  //   if (this.canvasTotal != null) {
  //     const vehicleTotalGradient = this.canvasTotal.nativeElement.getContext('2d').createLinearGradient(0, 270, 0, 0);
  //     vehicleTotalGradient.addColorStop(0, 'rgb(128, 212, 248)');
  //     vehicleTotalGradient.addColorStop(1, 'rgb(169, 229, 255)');
  //     this.totalVehiclesChartColors = [
  //       {
  //         backgroundColor: vehicleTotalGradient,
  //       },
  //     ];
  //   }
  // }

  addGradientToSiteTurnaroundChart() {
    if (this.canvasTurnaround != null) {
      const turnAroundGradient = this.canvasTurnaround.nativeElement.getContext('2d').createLinearGradient(20, 0, 220, 634);
      turnAroundGradient.addColorStop(0, 'rgb(128, 212, 248)');
      turnAroundGradient.addColorStop(1, 'rgb(169, 229, 255)');
      this.siteTurnaroundChartColors = [
        {
          backgroundColor: turnAroundGradient,
        },
      ];
    }
  }
}
