import { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,
  envName: 'production',
  cognito: {
    region: 'eu-west-1',
    clientId: '102dgfpfoh8h9umr8s4cooc59h',
    identityPoolId: 'eu-west-1:6d28fffc-7f0c-4a2e-9f0c-a3295b1c6947',
    userPoolId: 'eu-west-1_6oxM8qm1K',
    bucketId: 'ovsuitebucketdevelop-develop',
    oauth: {},
    passwordPolicyText: 'Password must contain at least 8 characters, 1 upper case, 1 lower case character and a number.',
  },
  apiUrl: {
    idm: 'https://ppc.api.account.linksuite.io',
    admin: 'https://ppc.api.admin.linksuite.io',
    warehouse: 'https://ppc.api.warehouse.linksuite.io',
    yard: 'https://ppc.api.yard.linksuite.io',
    yardWs: 'wss://ppc.api.yard.linksuite.io',
    execution: 'https://ppc.api.execution.linksuite.io',
    ceramicPortal: 'https://ppc.api.ceramic-portal.linksuite.io',
    order: 'https://ppc.api.order.linksuite.io',
  },
  webUrl: {
    idm: 'https://ppc.account.linksuite.io',
    admin: 'https://ppc.admin.linksuite.io',
    warehouse: 'https://ppc.warehouse.linksuite.io',
    yard: 'https://ppc.yard.linksuite.io',
    execution: 'https://ppc.execution.linksuite.io',
    ceramicPortal: 'https://ppc.ceramic-portal.linksuite.io',
    order: 'https://ppc.order.linksuite.io',
  },
  appId: {
    idm: 8,
    admin: 9,
    warehouse: 1,
    yard: 6,
    execution: 5,
    ceramicPortal: 7,
    order: 3,
  },
  other: {
    mapApiKey: 'AIzaSyAd5CAIBIGzFYxj8SU1i1VN3pTXf3O8Ugs',
    awsFileServiceApi: 'https://uun21udnjd.execute-api.eu-central-1.amazonaws.com/dev',
  },
  inhanceWorker: {
    authUrl: 'https://login.clover.inhancesc.com/oauth2/token',
    appClientId: '70g9l4b1fhh16qjs8f3snpe6of',
    appClientSecret: 'lv4p7m4ap1mbcievvav41jujoefh8dr24ukqc7u0j5dm7hfano9',
    apiUrl: 'https://ovq15csbyc.execute-api.eu-west-1.amazonaws.com/Prod/opsi/yard/',
  },
  redis: {

  },
};
