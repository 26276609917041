import { OVField, OVForm, OVSidebar, OVTable, OVEntity } from '@ov-suite/ov-metadata';
import { InventoryLocation } from "@ov-suite/models-admin";
import { Geofence } from "./geofence.model";
import { User } from "@ov-suite/models-idm";

@OVEntity({ name: 'GeofenceAlert', api: 'yardlink', tableName: 'geofence_alert' })
@OVForm([
  ['#Geofence Alert Details'],
  ['duration']
])
@OVSidebar([[''], [''], ['id']])
@OVTable<GeofenceAlert>([
  {
    key: 'id',
    title: 'Unique ID',
    type: 'number',
  },
  {
    key: 'duration',
    title: 'Duration',
    type: 'number',
  },
])
export class GeofenceAlert {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVField({
    type: 'number',
    title: 'Duration',
    placeholder: 'Required',
    tooltip: 'Duration',
    required: true,
  })
  duration: number;

  @OVField({ type: () => Geofence })
  geofence: Geofence;
}
