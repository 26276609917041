import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { GeofenceNotificationConfig } from '../../../../../../libs/models-yard/src/lib/geofence-notification-config.model';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: GeofenceNotificationConfig,
      api: 'yardlink',
      createTitle: 'GeofenceNotificationConfig',
      route: ['geofence-notification-config'],
      tableTitle: 'GeofenceNotificationConfig',
      permissionId: 901,
    }),
  ],
})
export class GeofenceNotificationConfigModule {}

// ATOM
