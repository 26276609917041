<div class="sidebar" (mouseenter)="hover=true" (mouseleave)="hover=false">
  <div class="logo">
    <a href="/" class="simple-text logo-mini">
      <div class="logo-image-small" [ngClass]="{ 'hidden' : open && !hover, 'show' : !open && !hover }"></div>
      <div class="logo-image-large" [ngClass]="{ 'hidden' : !open && !hover, 'show' : open && hover }"></div>
    </a>
    <button class="navbar-toggler btn btn-icon btn-round"
            [ngClass]="{ 'hidden' : !open && !hover }" (click)="minimizeSidebar()">
      <i class="fa fa-bars text-center visible-on-sidebar-mini" aria-hidden="true"></i>
      <i class="fa fa-times text-center visible-on-sidebar-regular" aria-hidden="true"></i>
    </button>
  </div>
  <div class="sidebar-wrapper">
  <ov-suite-user-profile-widget [open]="open" [hover]="hover"></ov-suite-user-profile-widget>
  <ul class="nav">
    <li routerLinkActive="active" exact="false" *ngFor="let menuitem of routes">
      <!--If is a single link-->
      <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link' && !menuitem.hidden">
        <i class="{{ menuitem.icontype }}" [ngClass]="{ 'center' : !open}" aria-hidden="true"></i>
        <p>{{ menuitem.title }}</p>
      </a>
      <!--If it have a submenu-->
      <a
        data-toggle="collapse"
        *ngIf="menuitem.type === 'sub' && menuitem.hidden != false"
        (click)="menuitem['isCollapsed'] = !menuitem['isCollapsed']"
      >
        <i class="{{ menuitem.icontype }}" aria-hidden="true"></i>
        <p>{{ menuitem.title }}<b class="caret"></b></p>
      </a>

      <!--Display the submenu items-->
      <div
        id="{{ menuitem.collapse }}"
        class="collapse"
        *ngIf="menuitem.type === 'sub'"
        [ngbCollapse]="!!menuitem.isCollapsed"
      >
        <ul class="nav">
          <li
            routerLinkActive="active" exact="false"
            *ngFor="let childitem of menuitem.children"
            #sub="routerLinkActive"
          >
            <a
              *ngIf="!childitem.hidden"
              [routerLink]="[menuitem.path, childitem.path]"
              [ngClass]="{ 'navbar-sub-item-open' : open,  'navbar-sub-item-close' : !open }">
              <span class="sidebar-mini-icon">{{ childitem.ab }}</span>
              <span class="sidebar-normal">{{ childitem.title }}</span>
            </a>
          </li>
        </ul>
      </div>
    </li>

    <li class="logout-menu-item" routerLinkActive="active" (click)="signOut()">
      <a>
        <ov-suite-icon class="text-light mr-2" [name]="'arrow-left'"> </ov-suite-icon>
        <p>LOG OUT</p>
      </a>
    </li>
  </ul>
</div>
</div>
