import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: any, searchValue: string): any {
    if (searchValue && Array.isArray(items)) {
      return items.filter(
        item =>
          item.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.lastName.toLowerCase().includes(searchValue.toLowerCase()),
      );
    } else {
      return items;
    }
  }
}
