import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import { ExitEntryEventService } from '../../services/exit-entry-event/exit-entry-event.service';
import { TableTopNavMenuModule } from '../../../../../ceramic-portal-web/src/app/components/table-top-nav-menu/table-top-nav-menu.module';
import { ArrivalsRoutes } from './arrivals-routing';
import { ArrivalsListComponent } from './list/arriavls-list.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ArrivalsRoutes),
    FormsModule,
    UiModule,
    TableTopNavMenuModule,
  ],
  declarations: [ArrivalsListComponent],
  providers: [ExitEntryEventService],
})
export class ArrivalsModule { }
