import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { YardWorkflowProcess } from '@ov-suite/models-yard';
import gql from 'graphql-tag';

@Injectable()
export class YardWorkflowProcessService extends AutoService<YardWorkflowProcess> {
  constructor(private readonly apollo: Apollo) {
    super(
      apollo.use('yardlink'),
      YardWorkflowProcess,
      'ProcessInstance',
      'ProcessInstances',
      'yardlink',
    );
  }

  public getById = (id: string, historical: boolean) => this.apollo.use('yardlink').query({
      query: gql(`query getProcessInstance($id: String!, $historical: Boolean!){
        getProcessInstance(id: $id, historical: $historical){
        id
        url
        name
        suspended
        ended
        processDefinitionId
        processDefinitionUrl
        processDefinitionName
        processDefinitionDescription
        activityId
        startUserId
        startTime
        callbackId
        callbackType
        tenantId
        completed,
        variables {
          name
          value
          type
        }
        tasks {
          data {
            id
            name
            startTime
            endTime
            assignee
          }
        }
        }
      }`),
      variables: { id, historical },
    });

  public deleteProcess = (id: string) => this.apollo.use('yardlink').query({
      query: gql(`mutation deleteProcessInstance($processInstanceId: String!){
        deleteProcessInstance(processInstanceId: $processInstanceId){
        }
      }`),
      variables: { processInstanceId: id },
    });
}
