import { Routes } from '@angular/router';
import { ArrivalsListComponent } from './list/arriavls-list.component';


export const ArrivalsRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ArrivalsListComponent
      }
    ]
  }
];
