import { OVField, OVForm, OVSidebar, OVTable, OVEntity } from '@ov-suite/ov-metadata';

@OVEntity({ name: 'Geofence', api: 'yardlink', tableName: 'geofence' })
@OVForm([['#Geofence Details'], ['name']])
@OVSidebar([[''], [''], ['id']])
@OVTable<Geofence>([
  {
    key: 'id',
    title: 'Unique ID',
    type: 'number',
  },
  {
    key: 'name',
    title: 'Name',
    type: 'string',
  },
  {
    key: 'description',
    title: 'Description',
    type: 'string',
  },
])
export class Geofence {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Name',
    placeholder: 'Required',
    tooltip: 'Name',
    required: true,
  })
  name: string;

  @OVField({
    type: 'string',
    title: 'Description',
    placeholder: 'Required',
    tooltip: 'Description',
    required: true,
  })
  description: string;

  @OVField({
    type: 'string',
    title: 'GeoJSON',
    placeholder: 'Required',
    tooltip: 'GeoJSON',
    required: false,
  })
  geojson: string;
}
