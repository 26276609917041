import { OVField, OVForm, OVSidebar, OVTable, OVSearchable, OVEntity } from '@ov-suite/ov-metadata';
import { MapLocation } from '@ov-suite/models-helper';

@OVEntity('GeofenceNotificationConfigStatus', 'shared')
export class GeofenceNotificationConfigStatus {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('GeofenceNotificationConfig', 'shared')
@OVForm([['GeofenceNotificationConfig Details'], ['id', 'geofenceName']])
@OVSidebar([['status'], ['id']])
@OVTable<GeofenceNotificationConfig>([
  {
    key: 'geofenceName',
    title: 'string',
    type: 'string',
  },
  {
    type: 'status',
    title: 'Status',
    key: 'status',
    id: 'status',
    orderKey: 'status.name',
  },
])
export class GeofenceNotificationConfig {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'GeofenceNotificationConfig Name',
    placeholder: 'Required',
    required: true,
  })
  geofenceName: string;

  @OVField({
    type: () => GeofenceNotificationConfigStatus,
    dropdown: true,
    selectionType: 'simple',
    title: 'Status',
    sidebar: true,
    generated: true,
    apis: ['adminlink'],
  })
  status?: GeofenceNotificationConfigStatus;
}
