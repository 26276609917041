import { Routes } from '@angular/router';
import { LiveMapDashboardComponent } from './dashboard/live-map-dashboard.component';

export const LiveMapDashboardRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: LiveMapDashboardComponent,
      },
    ],
  },
];
