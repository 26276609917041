import { Component } from '@angular/core';
import { environment } from '@ov-suite/helpers-shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'YardLink';
  authCheck = { complete: false };
  idmLinkUrl = environment.webUrl.idm;
  dev = !environment.production;
}
