import * as L from 'leaflet';
import { Marker } from 'leaflet';
import moment from 'moment';

export class MapMarkers {
  imei: string;

  marker: Marker;

  constructor(imei: string, marker: Marker) {
    this.imei = imei;
    this.marker = marker;
  }
}

export interface Payload {
  currentLocation?: number[];
  path?: string;
  accessCode?: string;
  registration?: string;
  registrationNumber?: string;
  // tslint:disable-next-line:no-any
  geojson?: any;
  ragStatus?: string;
  imei?: string;
  yardStatus?: YardStatus;
  geofenceSummary?: GeoFenceSummary;
  event?: string;
  geofence?: string;
  entryTimestamp?: Date;
  currentLon?: number;
  currentLat?: number;
}

interface YardStatus {
  entryTime?: string;
  turnAroundTime?: string;
}

interface GeoFenceSummary {
  name?: string;
  entryTime?: string;
  exitTime?: string;
}

export class Utils {
  public static convertDate(date) {
    return moment(date).format('hh:mm:ss A');
  }
}

export class Constants {
  public static VEHICLE_EXCEEDED_TOTAL_TIME_VIOLATION = {
    icon: L.icon({
      iconUrl: 'assets/img/leaflet/truck_black.svg',
      iconSize: [48, 48], // size of the icon
      iconAnchor: [24, 48], // point of the icon which will correspond to marker's location
      popupAnchor: [0, -48], // point from which the popup should open relative to the iconAnchor
    }),
  };

  public static VEHICLE_EXCEEDED_TIME_VIOLATION = {
    icon: L.icon({
      iconUrl: 'assets/img/leaflet/truck_red.svg',
      iconSize: [48, 48], // size of the icon
      iconAnchor: [24, 48], // point of the icon which will correspond to marker's location
      popupAnchor: [0, -48], // point from which the popup should open relative to the iconAnchor
    }),
  };

  public static VEHICLE_DELAYED_VIOLATION = {
    icon: L.icon({
      iconUrl: 'assets/img/leaflet/truck_orange.svg',
      iconSize: [48, 48], // size of the icon
      iconAnchor: [24, 48], // point of the icon which will correspond to marker's location
      popupAnchor: [0, -48], // point from which the popup should open relative to the iconAnchor
    }),
  };

  public static VEHICLE_NO_VIOLATION = {
    icon: L.icon({
      iconUrl: 'assets/img/leaflet/truck_green.svg',
      iconSize: [48, 48], // size of the icon
      iconAnchor: [24, 48], // point of the icon which will correspond to marker's location
      popupAnchor: [0, -48], // point from which the popup should open relative to the iconAnchor
    }),
  };

  public static POPUP_OPTIONS = {
    className: 'popupContent',
    autoClose: false,
    closeOnClick: false,
  };

  public static testJob: Payload = {
    currentLocation: [-25.516273, 28.184095],
    ragStatus: 'Red',
    imei: '14141414114',
    registration: 'BC19CZGP',
    accessCode: 'AC241412019',
    yardStatus: {
      entryTime: '2020-08-27T21:42:46.485Z',
    },
    geofenceSummary: {
      name: 'Tester1!',
      entryTime: '2020-08-27T21:42:46.485Z',
      exitTime: '2020-08-27T21:42:46.485Z',
    },
    geojson: [{
      type: 'LineString',
      coordinates: [[-25.511273, 28.184095], [-25.521273, 28.174095], [-25.531273, 28.164095]],
    }, {
      type: 'LineString',
      coordinates: [[-25.501273, 28.154095], [-25.5, 28.144095], [-25.49, 28.134095]],
    }],
  };

  public static testJob2: Payload = {
    currentLocation: [-25.515273, 28.184095],
    ragStatus: 'Green',
    imei: '14141414114',
    registration: 'BC19CZGP',
    accessCode: 'AC241412019',
    yardStatus: {
      entryTime: '2020-08-27T21:42:46.485Z',
    },
    geofenceSummary: {
      name: 'Tester2!',
      entryTime: '2020-08-27T21:42:46.485Z',
      exitTime: '2020-08-27T21:42:46.485Z',
    },
    geojson: [{
      type: 'LineString',
      coordinates: [[-25.511273, 28.184095], [-25.521273, 28.174095], [-25.531273, 28.164095]],
    }, {
      type: 'LineString',
      coordinates: [[-24.501273, 29.154095], [-23.5, 28.144095], [-22.49, 27.134095]],
    }],
  };

  public static testJob3: Payload = {
    currentLocation: [-25.514273, 28.184095],
    ragStatus: 'Amber',
    imei: '50000000',
    registration: 'BC19CZGP',
    accessCode: 'AC241412019',
    yardStatus: {
      entryTime: '2020-08-27T21:42:46.485Z',
    },
    geofenceSummary: {
      name: 'Hello!',
      entryTime: '2020-08-27T21:42:46.485Z',
      exitTime: '2020-08-27T21:42:46.485Z',
    },
    geojson: [{
      type: 'LineString',
      coordinates: [[-25.511273, 28.184095], [-25.521273, 28.174095], [-25.531273, 28.164095]],
    }, {
      type: 'LineString',
      coordinates: [[-25.501273, 28.154095], [-25.5, 28.144095], [-25.49, 28.134095]],
    }],
  };

  public static testJob4: Payload = {
    currentLocation: [-25.512273, 28.184095],
    ragStatus: 'Black',
    imei: '70000000',
    registration: 'BC19CZGP',
    accessCode: 'AC241412019',
    yardStatus: {
      entryTime: '2020-08-27T21:42:46.485Z',
    },
    geofenceSummary: {
      name: 'Hello!',
      entryTime: '2020-08-27T21:42:46.485Z',
      exitTime: '2020-08-27T21:42:46.485Z',
    },
    geojson: [{
      type: 'LineString',
      coordinates: [[-25.511273, 28.184095], [-25.521273, 28.174095], [-25.531273, 28.164095]],
    }, {
      type: 'LineString',
      coordinates: [[-25.501273, 28.154095], [-25.5, 28.144095], [-25.49, 28.134095]],
    }],
  };

  public static testJob5: Payload = {
    currentLocation: [-25.511273, 28.184095],
    ragStatus: 'Green',
    imei: '70000000',
    registration: 'BC19CZGP',
    accessCode: 'AC241412019',
    yardStatus: {
      entryTime: '2020-08-27T21:42:46.485Z',
    },
    geofenceSummary: {
      name: 'Hello!',
      entryTime: '2020-08-27T21:42:46.485Z',
      exitTime: '2020-08-27T21:42:46.485Z',
    },
    geojson: [{
      type: 'LineString',
      coordinates: [[-25.511273, 28.184095], [-25.521273, 28.174095], [-25.531273, 28.164095]],
    }, {
      type: 'LineString',
      coordinates: [[-25.501273, 28.154095], [-25.5, 28.144095], [-25.49, 28.134095]],
    }],
  };

  public static POLYLINE_STYLE = {
    className: 'polylineStyle',
  };
}
