import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { User, UserStatus } from '@ov-suite/models-idm';
import gql from 'graphql-tag';

@Injectable()
export class UserStatusService extends AutoService<UserStatus> {
  constructor(private readonly apollo: Apollo) {
    super(apollo.use('idmlink'), UserStatus, 'UserStatus', 'UserStatuses', 'idmlink');
  }
}

@Injectable()
export class UserService extends AutoService<User> {
  constructor(private readonly apollo: Apollo) {
    super(apollo.use('idmlink'), User, 'User', 'Users', 'idmlink');
  }

  async resetUserPassword(id: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.apollo
        .use('idmlink')
        .query({
          query: gql(`query GetResetUserPassword($id: Int!){
               getResetUserPassword(id: $id)
            }
          `),
          variables: { id },
        })
        .subscribe(response => {
          resolve(response['getResetUserPassword']);
        }, reject);
    });
  }

  async setUserPassword(id: number, password: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.apollo
        .use('idmlink')
        .query({
          query: gql(`query GetSetUserPassword($id: Int!, $password: String!){
               getSetUserPassword(id: $id, password: $password)
            }
          `),
          variables: { id, password },
        })
        .subscribe(response => {
          resolve(response['getSetUserPassword']);
        }, reject);
    });
  }
}
