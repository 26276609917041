import { Component } from '@angular/core';
import { ExitEntryEvent } from '@ov-suite/models-yard';
import { HierarchyTab } from '@ov-suite/ui';
import { ExitEntryEventService } from '../../../services/exit-entry-event/exit-entry-event.service';

@Component({
  selector: 'ov-suite-historical-list',
  templateUrl: './live-feed-list.component.html',
  styleUrls: ['./live-feed-list.component.scss'],
})
export class LiveFeedListComponent {
  // Class - Required
  formClass = ExitEntryEvent;

  tabs: HierarchyTab[] = [
    { title: 'Vehicles On Site / In Yard', path: '/live-feed' },
    // { title: 'Entered & Departed Collections', path: '/entered-departed-collections' },
  ];

  constructor(public exitEntryEventService: ExitEntryEventService) {}
}
