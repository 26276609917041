export function toTitleCase(text: string): string {
  return text.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export function camelCaseToTitleCase(text: string): string {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function kebabCaseToNormalCase(text: string): string {
  return text.replace(/([_-])/g, ' ');
}

export function slashToNormalCase(text: string): string {
  return text.replace(/([`/])/g, ' ');
}

export function propKeyToTitle(text: string): string {
  return this.toTitleCase(text.replace('.', ' '));
}

export function mapDeepObjects(key: string, object: Record<string, unknown> = {}): unknown[] | unknown {
  return key.split('.').reduce((prev, curr) => (prev ? prev[curr] : null), object);
}

export const queryParams = {
  PAGE_SIZE: '_pageSize',
  PARENT_ID: '_parentId',
  PAGE: '_page',
  ORDER_COLUMN: '_column',
  ORDER_DIRECTION: '_direction',
};
export const queryPlaceHolder = 'q;';

export function getUrlQueryMap(params): {
  filterKeys: Record<string, string[]>,
  queryKeys: Record<string, string[]>
} {
  let queryKeys = {}; // Number and Date types
  let filterKeys = {}; // All other types

  getNotExcludedParamKeys(params).forEach(key => {
    if (key.includes(queryPlaceHolder)) {
      const queryKey = key.replace(queryPlaceHolder, '');
      if (params.get(key).includes('date-range')) {
        queryKeys = {
          ...queryKeys,
          [queryKey]: [JSON.parse(params.get(key))],
        };
      } else {
        queryKeys = {
          ...queryKeys,
          [queryKey]: [params.get(key)],
        };
      }
    } else {
      filterKeys = {
        ...filterKeys,
        [key]: [params.get(key)],
      };
    }
  });
  return { filterKeys, queryKeys };
}

function getNotExcludedParamKeys(params): string[] {
  return params.keys.filter(key => !Object.keys(queryParams).map(queryKey => queryParams[queryKey]).includes(key));
}
