import { Component } from '@angular/core';
import { ExitEntryEvent } from '@ov-suite/models-yard';
import { HierarchyTab } from '@ov-suite/ui';
import { ExitEntryEventService } from '../../../services/exit-entry-event/exit-entry-event.service';

@Component({
  selector: 'ov-suite-entered-departed-collections-list',
  templateUrl: './entered-departed-collections-list.component.html',
  styleUrls: ['./entered-departed-collections-list.component.css'],
})
export class EnteredDepartedCollectionsListComponent {
  // Class - Required
  formClass = ExitEntryEvent;

  tabs: HierarchyTab[] = [
    { title: 'Vehicles On Site / In Yard', path: '/live-feed' },
    // { title: 'Entered & Departed Collections', path: '/entered-departed-collections' },
  ];

  constructor(public exitEntryEventService: ExitEntryEventService) {}
}
