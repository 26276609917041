import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ExitEntryEvent, VehicleTrack } from '@ov-suite/models-yard';
import gql from 'graphql-tag';
import { AutoService } from '@ov-suite/services';

@Injectable()
export class ReportsServiceExitEntry extends AutoService<ExitEntryEvent> {
  constructor(private readonly apollo: Apollo) {
    super(apollo.use('yardlink'), ExitEntryEvent, 'ReportGenerator', 'ReportGenerators', 'yardlink');
  }

  getExitEntryEvents(dateTo: string, dateFrom: string) {
    return this.apollo.use('yardlink').subscribe({
      query: gql`
        query getExitEntryEvents($dateTo: String!, $dateFrom: String!) {
          getExitEntryEvents(dateTo: $dateTo, dateFrom: $dateFrom) {
            label
            data
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        dateTo,
        dateFrom,
      },
    });
  }

  getVehiclesPerDay(dateTo: string, dateFrom: string) {
    return this.apollo.use('yardlink').subscribe({
      query: gql`
        query getVehiclesPerDay($dateTo: String!, $dateFrom: String!) {
          getVehiclesPerDay(dateTo: $dateTo, dateFrom: $dateFrom) {
            label
            data
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        dateTo,
        dateFrom,
      },
    });
  }

  getVehiclesPerWeek(dateTo: string, dateFrom: string) {
    return this.apollo.use('yardlink').subscribe({
      query: gql`
        query getVehiclesPerWeek($dateTo: String!, $dateFrom: String!) {
          getVehiclesPerWeek(dateTo: $dateTo, dateFrom: $dateFrom) {
            label
            data
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        dateTo,
        dateFrom,
      },
    });
  }

  getVehiclesPerMonth(dateTo: string, dateFrom: string) {
    return this.apollo.use('yardlink').subscribe({
      query: gql`
        query getVehiclesPerMonth($dateTo: String!, $dateFrom: String!) {
          getVehiclesPerMonth(dateTo: $dateTo, dateFrom: $dateFrom) {
            label
            data
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        dateTo,
        dateFrom,
      },
    });
  }

  getVehiclesPerYear(dateTo: string, dateFrom: string) {
    return this.apollo.use('yardlink').subscribe({
      query: gql`
        query getVehiclesPerYear($dateTo: String!, $dateFrom: String!) {
          getVehiclesPerYear(dateTo: $dateTo, dateFrom: $dateFrom) {
            label
            data
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        dateTo,
        dateFrom,
      },
    });
  }

  getAverageYardTurnAroundTimesPerDay(dateTo: string, dateFrom: string) {
    return this.apollo.use('yardlink').subscribe({
      query: gql`
        query getAverageYardTurnAroundTimesPerDay($dateTo: String!, $dateFrom: String!) {
          getAverageYardTurnAroundTimesPerDay(dateTo: $dateTo, dateFrom: $dateFrom) {
            label
            data
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        dateTo,
        dateFrom,
      },
    });
  }

  getAverageYardTurnAroundTimesPerWeek(dateTo: string, dateFrom: string) {
    return this.apollo.use('yardlink').subscribe({
      query: gql`
        query getAverageYardTurnAroundTimesPerWeek($dateTo: String!, $dateFrom: String!) {
          getAverageYardTurnAroundTimesPerWeek(dateTo: $dateTo, dateFrom: $dateFrom) {
            label
            data
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        dateTo,
        dateFrom,
      },
    });
  }

  getAverageYardTurnAroundTimesPerMonth(dateTo: string, dateFrom: string) {
    return this.apollo.use('yardlink').subscribe({
      query: gql`
        query getAverageYardTurnAroundTimesPerMonth($dateTo: String!, $dateFrom: String!) {
          getAverageYardTurnAroundTimesPerMonth(dateTo: $dateTo, dateFrom: $dateFrom) {
            label
            data
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        dateTo,
        dateFrom,
      },
    });
  }

  getAverageYardTurnAroundTimesPerYear(dateTo: string, dateFrom: string) {
    return this.apollo.use('yardlink').subscribe({
      query: gql`
        query getAverageYardTurnAroundTimesPerYear($dateTo: String!, $dateFrom: String!) {
          getAverageYardTurnAroundTimesPerYear(dateTo: $dateTo, dateFrom: $dateFrom) {
            label
            data
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        dateTo,
        dateFrom,
      },
    });
  }
}

@Injectable()
export class ReportsServiceVehicleTrack extends AutoService<VehicleTrack> {
  constructor(private readonly apollo: Apollo) {
    super(apollo.use('yardlink'), VehicleTrack, 'ReportGenerator', 'ReportGenerators', 'yardlink');
  }

  getAverageSiteTurnAroundTimes(dateTo: string, dateFrom: string) {
    return this.apollo.use('yardlink').subscribe({
      query: gql`
        query getAverageSiteTurnAroundTimes($dateTo: String!, $dateFrom: String!) {
          getAverageSiteTurnAroundTimes(dateTo: $dateTo, dateFrom: $dateFrom) {
            label
            data
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        dateTo,
        dateFrom,
      },
    });
  }
}
