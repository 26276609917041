import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import {
  ColumnData,
  ColumnDataButton,
  ColumnDataColumns,
  ColumnDataDate,
  ColumnDataDeepString,
  CRUD,
  getUrlQueryMap,
  Memo,
  PermissionType,
  queryParams,
} from '@ov-suite/helpers-shared';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OvAutoService } from '@ov-suite/services';
import { getWebCrud } from '@ov-suite/authguard-angular';
import { HasId } from '../ov-generic/ov-generic.types';

export interface FilterChange {
  filter: Record<string, string[]>;
  query: Record<string, string[]>;
  search: Record<string, string[]>;
}

type UserPermission = Record<PermissionType, boolean>;

@Component({
  selector: 'ov-suite-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<T extends HasId> implements OnInit {
  constructor(private readonly router: Router, public ngbModal: NgbModal, private readonly route: ActivatedRoute) {}

  userPermissions: Record<number, UserPermission> = {};

  selectedIndex = -1;

  pages: number[] = [];

  filler: null[] = [];

  public _columnHider = false;

  // Toggle Column Hider
  @Input()
  set columnHider(val: boolean) {
    this.toggleColumnHider();
    this._columnHider = val;
  }

  get columnHider(): boolean {
    return this._columnHider;
  }

  filteredColumns: string[] = [];

  // Used for localstorage of hidden columns. Required if hidden columns is used
  @Input() keyStore: string;

  // Might be Deprecated?
  @Input() service;

  @Input() ovAutoService: OvAutoService;

  // Not sure what used for
  @Input() loading = false;

  // Hide or Show Index
  @Input() showIndex = true;

  // Current Page
  @Input() currentPage = 0;

  // Hides Page Select
  @Input() showPageSelect = true;

  // Controls bootstrap styling
  @Input() striped = true;

  // Selectable show checkboxes to select rows
  @Input() selectableRows = false;

  // Whats this?
  @Input() showFiller = true;

  // Editable Rows
  @Input() editableRows = false;

  // Enable Page Change
  @Input() pageChangeEnabled = true;

  // Enable Filtering
  @Input() filterEnabled = true;

  isEditable = 'isEditable';

  hasChanged = 'hasChanged';

  editItemTip = null;

  newItemCount = 0;

  // Dropdown Data ?
  @Input() dropdownData = {};

  // Can rows be clicked?
  @Input() clickableRows = false;

  @Input() disableUrlParamsFiltering = false;

  // Callback when rows are clickable
  @Output() clickRow = new EventEmitter<T>();

  // Items Edited
  @Output() itemsEdited = new EventEmitter<T[]>();

  @Output() itemEdited = new EventEmitter<T>();

  // Item Select
  @Output() itemSelect = new EventEmitter<T[]>();

  // Callback when Filter Changes
  @Output() filterChange = new EventEmitter<FilterChange>();

  // Callback when Order Changes
  @Output() orderChange = new EventEmitter<{
    column: string;
    direction: 'ASC' | 'DESC';
    data: ColumnData<T>;
  }>();

  filterMapInput: Record<string, string[]> = {};

  filterMap: Record<string, string[]> = {};

  queryMap: Record<string, string[]> = {};

  searchMap: Record<string, string[]> = {};

  showMoreLessLimits: Record<number, number> = {};

  // Which column to order by
  @Input() order: {
    column: string;
    direction: 'ASC' | 'DESC';
    data: ColumnData<T>;
  } = {
    column: 'id',
    direction: 'ASC',
    data: {
      key: 'id',
      type: 'string',
      title: '',
    },
  };

  isSelected = 'isSelected';

  private _totalCount = 10;

  // Total Count
  @Input()
  set totalCount(num: number) {
    this._totalCount = num;
    this.updatePages();
  }

  get totalCount() {
    return this._totalCount;
  }

  private _pageSize = 10;

  // Page Size
  @Input()
  set pageSize(num: number) {
    this._pageSize = num;
    this.updatePages();
  }

  get pageSize() {
    return this._pageSize;
  }

  _data: T[];

  // Data. Required
  @Input() set data(data: T[]) {
    this._data = data;
    if (this.showFiller && data?.length < this.pageSize) {
      this.filler = new Array(this.pageSize - data.length).fill(null);
    } else {
      this.filler = [];
    }
    if (this.selectableRows) {
      this._data.forEach(item => (item[this.isSelected] = false));
    }
    if (this.editableRows) {
      this._data.forEach(item => (item[this.isEditable] = false));
    }
    this.getExtraColumns();
    if (!this.disableUrlParamsFiltering) {
      this.route.queryParamMap.subscribe(params => {
        if (params) {
          const queryKeys = getUrlQueryMap(params);
          this.currentPage = Number(params.get(queryParams.PAGE));
          this.filterMap = { ...queryKeys.filterKeys };
          this.queryMap = { ...queryKeys.queryKeys };
          this.filterMapInput = { ...queryKeys.filterKeys, ...queryKeys.queryKeys };
        }
      });
    }
  }

  get data() {
    return this._data;
  }

  extraColumns: { data: string[]; columnData: ColumnDataColumns<T> } = {
    data: [],
    columnData: null,
  };

  filteredColumnData: ColumnData<T>[];

  // ColumnData. Required
  // @Input() columnData: ColumnData<T>[];

  _columnData: ColumnData<T>[];

  // ColumnData. Required
  @Input()
  set columnData(input: ColumnData<T>[]) {
    this._columnData = input;
    this.checkPermissions();
  }

  get columnData() {
    return this._columnData;
  }

  // Callback when Page Changed
  @Output() changePage = new EventEmitter();

  @Output() columnButtonAction = new EventEmitter();

  @Output() deleteActionButton = new EventEmitter();

  @Output() editActionButton = new EventEmitter();

  // Callback when Page Size Changed
  @Output() changePageSize = new EventEmitter();

  totalPages = 1;

  defaultHiddenKeys = [];

  crud: CRUD = {
    create: true,
    read: true,
    update: true,
    delete: true,
  };

  // Array of hidden Fields
  @Input()
  set hideColumnKeys(event: string[]) {
    this.defaultHiddenKeys = event;
    if (!!event && event.length) {
      this.columnData = this.columnData.filter(col => !this.defaultHiddenKeys.some(dhk => dhk === col.hideColumnKey || dhk === col.id));
    }
  }

  routerLink(button: ColumnDataButton<T>, data: T): void {
    if (!button.routerLink) {
      return;
    }
    const { url } = this.router;
    const options: NavigationExtras = {
      queryParams: button.queryParams ? button.queryParams(data, url) : {},
    };
    this.router.navigate(button.routerLink(data, url), options);
  }

  updatePageSize(value) {
    this.changePageSize.emit(value);
  }

  updatePages(): void {
    const pagesRaw = this._totalCount / this._pageSize;
    let pages = Math.floor(pagesRaw);
    if (pages !== pagesRaw) {
      pages += 1;
    }
    this.totalPages = pages;
    if (pages > 5) {
      this.pages = new Array(5).fill('test').map((x, i) => i + this.currentPage);
    } else {
      this.pages = new Array(pages).fill('test').map((x, i) => i);
    }
  }

  // Select
  @Input() select: (item: T) => void = () => {};

  // Back
  @Input() back: (item?: T) => void = () => {};

  @Memo()
  getDeepValue(id: number, item: T, col: ColumnDataDeepString<T>): string {
    const keys = col.key.split('.');
    const value = keys.reduce((p, c) => p[c], item);
    return `${value}`;
  }

  shiftPages() {
    if (this.totalPages > 5) {
      if (this.pages[0] >= 0 && this.pages[4] <= this.totalPages + 1) {
        let factor = 0;
        if (this.currentPage - 2 <= 0) {
          factor = 2 - this.currentPage;
        } else if (this.currentPage >= this.totalPages - 2) {
          factor = this.totalPages - this.currentPage - 3;
        }
        this.pages = [
          this.currentPage - 2 + factor,
          this.currentPage - 1 + factor,
          this.currentPage + factor,
          this.currentPage + 1 + factor,
          this.currentPage + 2 + factor,
        ];
      }
    }
  }

  getDefaultFilteredColumns(): string[] {
    return this.columnData.filter(col => col.startHidden || this.defaultHiddenKeys.includes(col.hideColumnKey)).map(col => col.id);
  }

  ngOnInit() {
    this.showFiller = true;
    if (this.keyStore) {
      try {
        this.filteredColumns = JSON.parse(localStorage.getItem(this.keyStore)) ?? this.getDefaultFilteredColumns();
        if (this.filteredColumns.length) {
          this.filteredColumnData = this.columnData.filter(col => !this.filteredColumns.includes(col.id));
        } else {
          this.filteredColumnData = this.columnData;
        }
      } catch (e) {
        localStorage.removeItem(this.keyStore);
        this.filteredColumnData = this.columnData;
      }
    } else {
      this.filteredColumnData = this.columnData;
    }

    if (!this.data) {
      throw new TypeError('\'data\' is required');
    }
    if (!this.columnData) {
      throw new TypeError('\'columnData\' is required');
    }
  }

  persistColumnFilters() {
    if (this.keyStore) {
      localStorage.setItem(this.keyStore, JSON.stringify(this.filteredColumns));
    }
  }

  public getDate(date?: Date, column?: ColumnDataDate<T>): string {
    if (!date) {
      return '';
    }
    return moment(date).format(column.format ?? 'DD MMM YYYY');
  }

  public getDateTime(date?: Date): string {
    if (!date) {
      return '';
    }
    return moment(date).format('DD MMM YYYY, HH:mm');
  }

  public pageForward(): void {
    this.selectPage(this.currentPage + 1);
  }

  public pageLast(): void {
    this.selectPage(this.totalPages - 1);
  }

  public pageBack(): void {
    this.selectPage(this.currentPage - 1);
  }

  public pageFirst(): void {
    this.selectPage(0);
  }

  public selectPage(page: number) {
    if (page >= 0 && page < this.totalPages) {
      this.changePage.emit(page);
      this.currentPage = page;
      this.shiftPages();
      this.selectedIndex = -1;
    }
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent & { target: { localName: string } }) {
    if (event.target?.localName === 'input') {
      return;
    }

    switch (event.key) {
      case 'ArrowRight':
        event.stopPropagation();
        event.preventDefault();
        this.pageForward();
        break;
      case 'ArrowLeft':
        event.stopPropagation();
        event.preventDefault();
        this.pageBack();
        break;
      case 'ArrowDown':
        event.stopPropagation();
        event.preventDefault();
        this.selectedDown();
        break;
      case 'ArrowUp':
        event.stopPropagation();
        event.preventDefault();
        this.selectedUp();
        break;
      case ' ':
      case 'Enter':
        event.stopPropagation();
        event.preventDefault();
        if (this.selectedIndex >= 0 && this.selectedIndex < this.pageSize) {
          this.select(this.data[this.selectedIndex]);
        }
        break;
      case 'Backspace':
        event.stopPropagation();
        event.preventDefault();
        this.back();
    }
  }

  selectedDown() {
    if (this.selectedIndex < Math.min(this.pageSize, this.data?.length ?? 0) - 1) {
      this.selectedIndex += 1;
    } else {
      this.selectedIndex = -1;
    }
  }

  selectedUp() {
    if (this.selectedIndex > -1) {
      this.selectedIndex -= 1;
    } else {
      this.selectedIndex = Math.min(this.pageSize, this.data?.length ?? 0) - 1;
    }
  }

  onSelect(event, item?: T): void {
    if (!item) {
      if (event.target.checked) {
        this.data.forEach(d => (d[this.isSelected] = true));
      } else {
        this.data.forEach(d => (d[this.isSelected] = false));
      }
    } else {
      item[this.isSelected] = !item[this.isSelected];
    }
    const selection: T[] = [...this.data.filter(d => d[this.isSelected])];
    this.itemSelect.emit(selection);
  }

  isAllSelected(): boolean {
    return this.data.every(item => item[this.isSelected] === true);
  }

  addNewItem(): void {
    const copy = {} as T;
    copy['newId'] = ++this.newItemCount;
    copy[this.isEditable] = true;
    this.data.push(copy as T);
  }

  // TODO refactor this, consider removing this on monday
  itemChanged(item: T): void {
    this.itemEdited.emit(item);
  }

  emitItemChanges(): void {
    this.itemsEdited.emit([...this.data.filter(d => d[this.hasChanged])]);
  }

  cancel(item: T): void {
    if (item['newId']) {
      this.data = this.data.filter(d => d['newId'] !== item['newId']);
    } else {
      item[this.hasChanged] = false;
    }
    this.emitItemChanges();
  }

  hasChanges(): boolean {
    return this.data.some(d => d[this.hasChanged] === true);
  }

  getExtraColumns(): void {
    const column = this.columnData?.find(d => d.type === 'column') as ColumnDataColumns<T>;
    if (column) {
      this.extraColumns.columnData = column;
      const mappedData = this.data.map(d => column.mapData(d)).filter(i => !!i);
      this.extraColumns.data = (Array.isArray(mappedData[0])
        ? Array.from(new Set(mappedData.reduce((prev, cur) => (Array.isArray(cur) ? [...prev, ...cur] : [...prev, cur]), [])))
        : mappedData) as string[];
    }
  }

  toggleColumnHider() {
    if (this.columnHider) {
      this.filteredColumnData = this.columnData.filter(col => !this.filteredColumns.includes(col.id));
    } else {
      this.filteredColumnData = this.columnData;
    }
  }

  isColVisible(id: string) {
    return !this.filteredColumns.includes(id);
  }

  toggleCol(id: string, event?: Event) {
    event?.stopPropagation();
    if (this.filteredColumns.includes(id)) {
      this.filteredColumns = this.filteredColumns.filter(item => item !== id);
    } else {
      this.filteredColumns.push(id);
    }
    this.persistColumnFilters();
  }

  onFilterChange(columnData: ColumnData<T>, event) {
    const value = event?.target?.value;
    switch (columnData.type) {
      case 'buttons':
        break;
      case 'column':
        break;
      case 'other':
        columnData.keys.forEach(key => {
          if (value) {
            this.filterMap[key] = [value];
          } else {
            delete this.filterMap[key];
          }
        });
        break;
      case 'date':
        this.queryMap[(columnData.filterKey as string) ?? (columnData.key as string)] = [event];
        break;
      case 'number':
        this.queryMap[(columnData.filterKey as string) ?? (columnData.key as string)] = [value];
        break;
      case 'status':
        this.filterMap['status.name'] = [value];
        break;
      case 'pills':
        break;
      default:
        this.filterMap[(columnData.filterKey as string) ?? (columnData.key as string)] = [value];
    }
    const output: FilterChange = {
      filter: this.filterMap,
      query: this.queryMap,
      search: this.searchMap,
    };
    this.filterChange.emit(output);
  }

  onColumnHeaderClick(column: ColumnData<T>) {
    if (!this.columnHider) {
      if (!column.disableSorting) {
        if (this.order.column === column.id) {
          this.order.direction = this.order.direction === 'ASC' ? 'DESC' : 'ASC';
        } else {
          this.order.column = column.id;
          this.order.direction = 'ASC';
          this.order.data = column;
        }
        this.orderChange.emit(this.order);
      }
    } else {
      this.toggleCol(column.id);
    }
  }

  onClick(item: T) {
    if (this.clickableRows) {
      this.clickRow.emit(item);
    }
  }

  columnItemAction(event, type: 'edit' | 'delete') {
    if (type === 'edit') {
      this.editActionButton.emit(event);
    } else if (type === 'delete') {
      this.deleteActionButton.emit(event);
    } else {
      this.columnButtonAction.emit(event);
    }
  }

  getObjectType(type) {
    return typeof type;
  }

  setValueByKey(item, key, data) {
    const keys = key.split('.');
    const [firstKey] = keys;
    const tempObject = {};

    if (keys.length < 2) {
      data[firstKey] = item;
    } else {
      for (let i = 1; i < keys.length; i++) {
        tempObject[keys[keys.length - i]] = item;
        data[firstKey] = { ...data[firstKey], ...tempObject };
      }
    }
    this.itemEdited.emit(data);
  }

  checkPermissions() {
    this.columnData.forEach(col => {
      const getCrud = (permissionId: number) => {
        getWebCrud(permissionId).then(crud => {
          this.userPermissions[permissionId] = {
            [PermissionType.create]: crud.create,
            [PermissionType.read]: crud.read,
            [PermissionType.update]: crud.update,
            [PermissionType.delete]: crud.delete,
          };
        });
      };

      if (col.type === 'buttons') {
        col.buttons.forEach(button => {
          if (button.permissionId && button.permissionType && !this.userPermissions[button.permissionId]) {
            getCrud(button.permissionId);
          }
        });
      }
      if (col.type === 'time' || col.type === 'dropdown') {
        if (col.permissionId && col.permissionType && !this.userPermissions[col.permissionId]) {
          getCrud(col.permissionId);
        }
      }
    });
  }

  onToggleLessMore(limit: number, index: number, itemsCount: number, event: HTMLElement) {
    if (event.textContent.trim().toLocaleLowerCase() === 'show more') {
      event.textContent = 'show less';
      this.showMoreLessLimits[index] = itemsCount;
    } else {
      this.showMoreLessLimits[index] = limit;
      event.textContent = 'show more';
    }
  }
}
