<div class="main-content">
  <div class="main-container">
    <ov-suite-hierarchy-table
      [title]="'Arrivals'"
      [formClass]="formClass"
      [service]="exitEntryEventService"
      [excludeColumns]="5"
      [hasBulkUpload]="false"
      [hideAddButton]="true"
      [filter]="filter"
      [overrideServiceMethod]="'getArrivals'"
      [hideColumnKeys]="['exitUser', 'exitTime', 'entryTime', 'duration', 'entryUser']"
    [showFiller]="false"
      [showTopBar]="true"
      [filterEnabled]="false"
      [showPageSelect]="false"
      [pageChangeEnabled]="false"
      [defaultOrderDirection]="'ASC'"
    >
    </ov-suite-hierarchy-table>
  </div>
</div>
