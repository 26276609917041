import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { ExitReason } from '@ov-suite/models-yard';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: ExitReason,
      api: 'yardlink',
      createTitle: 'Exit Reason',
      route: ['exit-reason'],
      tableTitle: 'Exit Reasons',
      showFiller: false,
    }),
  ],
})
export class ExitReasonModule {}
